import React, { useEffect } from "react";
import Contact from "../../Components/Contact";
import ManagerCard from "./ManagerCard";
import SecondaryBanner from "../../Components/SecondaryBanner";
export default function BranchManagers() {
  useEffect(() => {
    document.title = "Branch Managers";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "About Us", "Branch Managers"],
    bannerTitle: "Branch Managers",
  };
  return (
    <>
      <SecondaryBanner bannerData={bannerData} />
      <ManagerCard />
    </>
  );
}
