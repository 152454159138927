import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Contact from "../../Components/Contact";
import crc1 from "../../images/crc1.jpg";
import crc2 from "../../images/crc2.jpg";
import crc3 from "../../images/crc3.jpg";
import crc4 from "../../images/crc4.jpg";
import notice1 from "../../images/notice1.jpg";
import notice2 from "../../images/notice2.jpg";
import gurkha from "../../images/gurkha-square-log.png";
import blb from "../../images/Information regarding Branchless Banking(BLB).png"
export default function CsrNoticeAndUpdatesPage() {
  const [mapData, setMapData] = useState(null);
  const [csr, setCsr] = useState(null);
  const [image, setImage] = useState();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    switch (id) {
      case "csrList":
        setCsr(1);
        // setMapData([
        //     {
        //         "id":1,
        //         "list":"Handed over CCTV System to Miklajung Gaupalika"
        //     },                    {
        //         "id":2,
        //         "list":"Bagmati Cleaning Campaign"
        //     },
        //     {
        //         "id":3,
        //         "list":"Donated Wheelchair & Television for Hospital"
        //     },
        //     {
        //         "id":4,
        //         "list":"Dristributed Water in Boudha Rabne Program"
        //     }

        // ])
        break;
      case "noticeUpdate":
        setCsr(2);
        break;
      case "csrList1":
        setCsr(3);
        setImage(crc1);
        break;
      case "csrList2":
        setCsr(4);
        setImage(crc2);
        break;
      case "csrList3":
        setCsr(5);
        setImage(crc3);
        break;
      case "csrList4":
        setCsr(6);
        setImage(crc4);
        break;
      case "noticeUpdate5":
        setCsr(7);
        setImage(notice1);
        break;
      case "noticeUpdate6":
        setCsr(8);
        setImage(gurkha);
        break;
      case "noticeUpdate7":
        setCsr(9);
        setImage(gurkha);
        break;
      case "noticeUpdate8":
        setCsr(10);
        setImage(notice2);
        break;
      case "noticeUpdate9":
        setCsr(11);
        setImage(gurkha);
        break;
      case "noticeUpdate10":
        setCsr(11);
        setImage(blb);
        break;
      default:
        break;
    }
  }, [id]);
  return (
    <main className="display-image">
      <div className="container">
        <div>
          {csr == 1 && (
            <div className="card-big card-misc">
              <div className="card-misc-header">
                <h2>Corporate Social Responsibility</h2>
              </div>
              <ul className="card-misc-list">
                <li>
                  <Link to="./csrList1">
                    Handed over CCTV System to Miklajung Gaupalika
                  </Link>
                </li>
                <li>
                  <Link to="./csrList2">Bagmati Cleaning Campaign</Link>
                </li>
                <li>
                  <Link to="./csrList3">
                    Donated Wheelchair & Television for Hospital
                  </Link>
                </li>
                <li>
                  <Link to="./csrList4">
                    Dristributed Water in Boudha Rabne Program
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {csr == 2 && (
            <div className="card-small card-misc">
              <div className="card-misc-header">
                <h2>Notice and updates</h2>
              </div>
              <ul className="card-small-list">
                <li>
                  <a
                    href="https://gurkhasfinance.com.np/assets/Dormat Account List Disclosure.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dormant Account List
                  </a>
                </li>
                <li>
                  <Link to="./noticeUpdate5">
                    ASBA Service from all Branches
                  </Link>
                </li>
                <li>
                  <Link to="./noticeUpdate6">
                    Share Auction Notice - 2074-02-01
                  </Link>
                </li>
                <li>
                  <Link to="./noticeUpdate7">Residential Meet</Link>
                </li>
                <li>
                  <Link to="./noticeUpdate8">NRB Notice</Link>
                </li>
                <li>
                  <Link to="./noticeUpdate9">Gurkhas App Update</Link>
                </li>
              </ul>
            </div>
          )}
          {csr == 3 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Handed over CCTV System to Miklajung Gaupalika</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 4 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Bagmati Cleaning Campaign</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 5 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Donated Wheelchair & Television for Hospital</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 6 && (
            <>
              <div className="card-misc-header">
                <h2>Dristributed Water in Boudha Rabne Program</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}

          {csr == 7 && (
            <>
              <div className="custome-card-misc-header">
                <h2>ASBA Service from all Branches</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 8 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Share Auction Notice - 2074-02-01</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 9 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Residential Meet</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 10 && (
            <>
              <div className="custome-card-misc-header">
                <h2>NRB Notice</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
          {csr == 11 && (
            <>
              <div className="custome-card-misc-header">
                <h2>Gurkhas App Update</h2>
              </div>
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
