import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import NonFundedCredit from "./NonFundedCredit";
import SmeLoan from "./SmeLoan";

const BusinessLoan = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    if (pageID === "sme-loan") {
      document.title = "SME Loan";
      return "SME Loan";
    } else if (pageID === "non-funded-credit") {
      document.title = "Non Funded Credit Facilities";
      return "Non Funded Credit Facilities";
    }
  }
  const bannerData = {
    breadcrumb: [
      "Home",
      "Explore Products",
      "Loan and Advance",
      "Business Loan",
      getPageTitle(id),
    ],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "sme-loan" && <SmeLoan />}
              {id === "non-funded-credit" && <NonFundedCredit />}
            </div>
            <div className="col-md-3">
              <div className="related-products">
                <div className="header-box">
                  <div className="title" style={{ marginBottom: "35px" }}>
                    <p>
                      <strong>Related Products</strong>
                    </p>
                  </div>
                </div>
                <ul>
                  {id !== "sme-loan" && (
                    <li>
                      <Link to="/business-loan/sme-loan">SME Loan</Link>
                    </li>
                  )}
                  {id !== "non-funded-credit" && (
                    <li>
                      <Link to="/business-loan/non-funded-credit">
                        Non Funded Credit Facilities
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BusinessLoan;
