import React from "react";
import secondaryBannerImage from "../../images/remmit.png";

const RemitServices = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Remit service is easy way of getting funds transferred from international and domestic customers. Gurkhas can provide account deposit or cash withdrawl facilities on remit received through difference agents.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Agency Remittance Partners</p>
          </div>
          <ul>
            <li>GME (Best Remit Merger with GME)</li>
            <li>Prabhu Remit</li>
            <li>IME Remit</li>
            <li>Himal Remit</li>
            <li>City Express Remit</li>
            <li>Western Union</li>
            <li>Nabil Remit</li>
            <li>United Remit</li>
            <li>Samsara Remit</li>
            <li>Ipay Remit</li>
            <li>JME</li>
            <li>Kumari Remit</li>
          </ul>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default RemitServices;
