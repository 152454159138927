import React from "react";
import { Link } from "react-router-dom";
export default function MiscCards() {
  return (
    <section className="misc-cards">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="card-big card-misc">
              <div className="card-misc-header">
                <h2>Corporate Social Responsibility</h2>
              </div>
              <div className="card-misc-paragraph">
                <p>
                  We believe in growth that goes beyond numbers. The kind that
                  includes everyone. The only kind that lasts. And so, we focus
                  on doing what’s right. For our stakeholders. For the planet.
                  And for society at large.
                </p>
              </div>
              <ul className="card-misc-list">
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/csrList1">
                    Handed over CCTV System to Miklajung Gaupalika
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/csrList2">
                    Bagmati Cleaning Campaign
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/csrList3">
                    Donated Wheelchair & Television for Hospital
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/csrList4">
                    Dristributed Water in Boudha Rabne Program
                  </Link>
                </li>
              </ul>
              <li className="misc-link">
                <Link to="/CsrNoticeAndUpdatesPage/csrList">Learn More</Link>
              </li>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card-small card-misc">
              <div className="card-misc-header">
                <h2>Notice and updates</h2>
              </div>
              <ul className="card-small-list">
                <li>
                  <a
                    href="https://gurkhasfinance.com.np/assets/Dormat Account List Disclosure.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dormant Account List
                  </a>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate5">
                    ASBA Service from all Branches
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate6">
                    Share Auction Notice - 2074-02-01
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate7">
                    Residential Meet
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate8">
                    NRB Notice
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate9">
                    Gurkhas App Update
                  </Link>
                </li>
                <li>
                  <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate10">
                    Information regarding Branchless Banking(BLB)
                  </Link>
                </li>
              </ul>
              <li className="misc-link">
                <Link to="/CsrNoticeAndUpdatesPage/noticeUpdate">
                  View Notices
                </Link>
              </li>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
