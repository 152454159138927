import React, { useState } from "react";
import nariBachatIcon from "../../images/nari-bachat-05.svg";
import balBachatIcon from "../../images/bal-bachat-06.svg";
import jesthaIcon from "../../images/jestha-nagarik bachat-07.svg";
import khukuriIcon from "../../images/gurkhas pension.svg";
import mortgageIcon from "../../images/mortagage loan.png";
import professionalIcon from "../../images/professional loan.svg";
import goldIcon from "../../images/gold loan.png";
import marginIcon from "../../images/Margin Landing.png";
import digitalIcon from "../../images/internet banking.png";
import mobileIcon from "../../images/mobile banking.svg";
import qrIcon from "../../images/QR.svg";
import asbaIcon from "../../images/ASBA.svg";
import remitIcon from "../../images/remittance.svg";
import lockerIcon from "../../images/locker.png";
import dematIcon from "../../images/Demat.png";
import debitIcon from "../../images/Debit Card.svg";
import { Link } from "react-router-dom";
const Services = () => {
  const [activeTab, setActiveTab] = useState("deposit");
  const datas = [
    {
      id: "deposit",
      content: [
        {
          id: "nari",
          title: "Nari Bachat Khata",
          body: "Nari Bachat aims to avail a saving solution to the range of women customers by connecting them with to service of modern day banking practices, enabling them to take control over their financial behavior and decisions.",
          image: nariBachatIcon,
          link: "/saving/nari-bachat",
        },
        {
          id: "bal",
          title: "Bal Bachat Khata",
          body: "Bal Bachat Saving account is the product designed to attract children. It is the privileged account for the children to encourage saving.",
          image: balBachatIcon,
          link: "/saving/bal-bachat",
        },
        {
          id: "jestha-nagarik",
          title: "Jestha Nagarik Bachat Khata",
          body: "People of old age can open this account",
          image: jesthaIcon,
          link: "/saving/jesthanagarik",
        },
        {
          id: "pension",
          title: "Gurkhas Pension Bachat Khata",
          body: "Person receiving pensions can open this account.",
          image: khukuriIcon,
          link: "/saving/gurkha-pension-saving",
        },
      ],
    },
    {
      id: "loan",
      content: [
        {
          id: "nari",
          title: "Mortgage Loan",
          body: "A mortgage loan or, simply, the mortgage is used either by purchasers of real property to raise funds to buy real estate, or alternatively by existing property owners to raise funds for any purpose while putting a lien on the property being mortgaged. The loan is “secured” on the borrower’s property through a process known as mortgage origination.",
          image: mortgageIcon,
          link: "/retail-loan/mortgage-loan",
        },
        {
          id: "bal",
          title: "Professional Loan",
          body: "A professional loan, as the name suggests, is a loan that can be taken out by professionals like accountants, doctors, journalists, engineers, dentists, company secretaries, surveyors, lawyers, architects, management consultants, and professionals involved in consultancy. It is non-collateral based loan.",
          image: professionalIcon,
          link: "/retail-loan/professional-loan",
        },
        {
          id: "jestha-nagarik",
          title: "Gold Loan",
          body: "Gold loan or loan against gold is a secured loan in which a customer pledges his/her gold ornaments as collateral with a gold loan company. The bank, in turn, gives a loan amount as per the market value of gold to the customer. It is a very quick and easy way of fulfilling one’s financial needs as compared to the other loans.",
          image: goldIcon,
          link: "/retail-loan/gold-loan",
        },
        {
          id: "pension",
          title: "Margin Lending",
          body: "Margin lending is a type of loan that allows you to borrow money to invest, by using your existing shares, managed funds, and/or cash as security. It’s a type of gearing which is borrowing money to invest.",
          image: marginIcon,
          link: "/retail-loan/margin-lending",
        },
      ],
    },
    {
      id: "digital-banking",
      content: [
        {
          id: "nari",
          title: "E-Banking",
          body: "Gurkhas Finance limited e-banking facilities to customers taking e-banking facilities.",
          image: digitalIcon,
          link: "/transactional-banking/e-banking",
        },
        {
          id: "bal",
          title: "Mobile Banking",
          body: "Mobile Banking is a digital banking product for seamless customer experience across all the platforms via App. With enhanced mobile banking systems built into the app, our customers will benefit better. ",
          image: mobileIcon,
          link: "/transactional-banking/mobile-banking",
        },
        {
          id: "jestha-nagarik",
          title: "QR Scan and Pay",
          body: "As a paperless system of making payment, Gurkhas Finance Limited offers an alternative to the traditional systems, a payment via QR Code scanning through Mobile. ",
          image: qrIcon,
          link: "/transactional-banking/qr-scan-pay",
        },
        {
          id: "pension",
          title: "ASBA/C-ASBA",
          body: "Application Supported by Blocked Amount (ASBA) is a process for applying to public/rights issues submitted by investors by blocking the application money in their bank account while subscribing to the issue. ",
          image: asbaIcon,
          link: "/transactional-banking/asba",
        },
      ],
    },
    {
      id: "other-services",
      content: [
        {
          id: "nari",
          title: "Remit Services",
          body: "Remit service is easy way of getting funds transferred from international and domestic customers. Gurkhas can provide account deposit or cash withdrawl facilities on remit received through difference agents. ",
          image: remitIcon,
          link: "/transactional-banking/remit-services",
        },
        {
          id: "bal",
          title: "Locker Services",
          body: "Gurkhas has been providing Safe Deposit Locker facility to its valued customers in order to safeguard valuable belongings. Our Locker services are well spread throughout the nation and we have various sizes to cater this need.",
          image: lockerIcon,
          link: "/transactional-banking/locker-services",
        },
        {
          id: "jestha-nagarik",
          title: "DEMAT",
          body: "Gurkhas Finance Ltd obtained the license to act as Depository Participant (DP) and provide Demat related services. As a DP, Gurkhas Finance has been providing below listed services to its customers.",
          image: dematIcon,
          link: "/transactional-banking/demat",
        },
        {
          id: "pension",
          title: "Debit Card",
          body: "GUFL Debit Card can be used in any of the ATMs and POS machines displaying SCT and Union Pay Logo throughout Nepal.",
          image: debitIcon,
          link: "/transactional-banking/debit-card",
        },
      ],
    },
  ];
  return (
    <section className="services">
      <div className="container">
        <div className="main-content">
          <div className="service-head">
            <h1>
              We have added different services over years for you.Explore our
              services.
            </h1>
          </div>
          <div className="tab-container">
            <div
              className={`tab deposit ${activeTab === "deposit" ? "tab-active" : ""
                }`} ser
              onClick={() => setActiveTab("deposit")}
            >
              <p>Deposit</p>
            </div>
            <div
              className={`tab loan ${activeTab === "loan" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("loan")}
            >
              <p>Loan</p>
            </div>
            <div
              className={`tab digital-banking ${activeTab === "digital-banking" ? "tab-active" : ""
                }`}
              onClick={() => setActiveTab("digital-banking")}
            >
              <p>Digital Banking</p>
            </div>
            <div
              className={`tab other-services ${activeTab === "other-services" ? "tab-active" : ""
                }`}
              onClick={() => setActiveTab("other-services")}
            >
              <p>Other Services</p>
            </div>
          </div>
          {datas.map((data) => {
            return (
              data.id === activeTab && (
                <div
                  className="service-content service-content-active"
                  id={data.id}
                  key={data.id}
                >
                  {data.content.map((content) => {
                    return (
                      <div className="wrapper" key={content.id}>
                        <div className="service-card">
                          <div className="content">
                            <div className="image-container">
                              <img src={content.image} alt="" />
                            </div>
                            <div className="service-card-head">
                              <h3>{content.title}</h3>
                            </div>
                            <div className="text-container">
                              <p>{content.body}</p>
                            </div>
                          </div>
                          <li className="misc-link">
                            <Link to={content.link}>Learn More </Link>
                          </li>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
