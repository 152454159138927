import React from "react";
import secondaryBannerImage from "../../images/mobile banking.png";

const MobileBanking = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Introduction</p>
            <div className="paragraph">
              <p className="small-light-text">
                Mobile Banking is a digital banking product for seamless
                customer experience across all the platforms via App. With
                enhanced mobile banking systems built into the app, our
                customers will benefit better. With this official app, they can
                enjoy easy banking from their personal devices anywhere and
                anytime, and can manage their accounts on the move. Security
                features will help them remain confident about their
                transactions. It’s available for download at App Store and
                Google Play.
              </p>
            </div>
          </div>
        </div>
        <div className="text-list">
          <div className="header-box " style={{ marginTop: "40px" }}>
            <p className="title">Facilities</p>
          </div>
          <li> View Account Statement</li>
          <li> QR Payments</li>
          <li> Biometric Login</li>
          <li> Schedule Payment</li>
          <li> Recharge Card and Top up</li>
          <li> Work on SMS & Internet Mode</li>
          <li> Interbank Fund Transfer</li>
          <li> NEA & Water bill Payment</li>
          <li> Airlines, Bus & Movie ticketing platform</li>
          <li> Fonepay network merchant payment</li>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default MobileBanking;
