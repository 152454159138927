import avatar from '../../images/avatar.webp'
import gurkha from '../../images/gurkha-square-log.png'
import sunita from '../../images/sunita-rai.jpg'
import nilamRai from '../../images/neelam-rai.jpg'
import rajinRai from '../../images/rajin-rai.jpg'
import jeevanRai from '../../images/jeevan-kumar-rai.jpg'
import sushilKoirala from '../../images/sushil.jpg'
import dhirajGurung from '../../images/dhiraj-gurung.jpg'
import rohitaIngam from '../../images/rohita-ingam.jpg'
import pramilaChaulagain from '../../images/pramila-chaulagain.jpeg'
import shovaBajracharya from '../../images/shova-bajracharya.jpg'
import prasannaSubba from '../../images/Prasanna.jpg'
import shantiSingh from '../../images/shanti.jpg'
import ramBahadurRawal from '../../images/ram-bahadur-rawal.jpg'
import prakashBhattarai from '../../images/prakash-bhattarai .jpg'
import sumanSubba from '../../images/suman-subba.jpg'
import umaRajGurung from '../../images/uma-raj-gurung.jpg'
import harathiMathema from '../../images/harati-mathema.png'
import surajRaiBhojpur from '../../images/suraj-rai-bhojpur.jpg'
import tapBahadurKhadka from '../../images/tap-bahadur-khadka.bmp'
import mohamadAnsari from '../../images/mohamad-ansari.jpg'
import giridharShahi from '../../images/giridhar-shahi.jpg'
import jiwanKajiKarki from '../../images/kaji.jpg'
import jyotiOmShrestha from '../../images/jyoti-om-shrestha.jpg'
import sameerKamat from '../../images/sameer-kamat.jpg'
import bishwashKhadka from '../../images/bishwash-khadka.jpg'
import kalyaniRai from '../../images/kalyani-rai.jpeg'
import lilaRaj from '../../images/lila-raj-thumbapo.jpg'
import ratnaBahadurThapa from '../../images/ratna-bahadur-thapa.jpg'
import pramodKumar from '../../images/Pramod Kumar Jha.jpg'
import manishAcharya from '../../images/manish acharya.jpg'
import rajeshJha from '../../images/rajesh Jha.jpg'
import rajan from '../../images/Rajan.jpg'
// import avatar from "../../images/avatar.webp";

// import prakashBhattarai from "../../images/prakash-bhattarai .jpg";
// import mohamadAnsari from "../../images/mohamad-ansari.jpg";
// import jyotiOmShrestha from "../../images/jyoti-om-shrestha.jpg";
// import sameerKamat from "../../images/sameer-kamat.jpg";
// import kalyaniRai from "../../images/kalyani-rai.jpeg";
// import lilaRaj from "../../images/lila-raj-thumbapo.jpg";
import dilipKumarMandal from '../../images/dilip-kumar-mandal.png'

export const data = [
  // {
  //   OfficeLocation: 'Head Office',
  //   name: 'Rajesh Kumar Jha',
  //   image: rajeshJha,
  //   province: 'Bagmati Province ',
  //   phone: '01-4437401, 4437403, 4430527, 9849327397',
  //   email: 'info@gurkhasfinance.com.np',
  // },

  {
    OfficeLocation: 'Boudha',
    name: 'Nilam Rai',
    image: nilamRai,
    province: 'Bagmati Province ',
    phone: '01-4561155, 4590522',
    email: 'boudha@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Damak',
    name: 'Rajin Rai',
    image: rajinRai,
    province: 'Province 1',
    phone: '023-575389',
    email: 'damak@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Dharan',
    name: 'Jeevan Kumar Rai',
    image: jeevanRai,
    province: 'Province 1',
    phone: ' 025-533791, 533790',
    email: 'dharan@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Newroad',
    name: 'Susil Koirala',
    image: sushilKoirala,
    province: 'Bagmati Province',
    phone: '01-5713241, 5713041, 5713228',
    email: 'newroad@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Itahari',
    name: 'Dhiraj Kumar Gurung',
    image: dhirajGurung,
    province: 'Province 1',
    phone: '025-586928',
    email: 'itahari@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Birtamode',
    name: 'Rohita Ingnam',
    image: rohitaIngam,
    province: 'Province 1',
    phone: ' 023-536421',
    email: 'birtamode@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Satdobato',
    name: 'Sunita Rai',
    image: sunita,
    province: 'Bagmati Province',
    phone: '01-5151232, 5151247',
    email: 'satdobato@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Bhaktapur',
    name: 'Pramila Chaulagain',
    image: pramilaChaulagain,
    province: 'Bagmati Province',
    phone: '01-6619268, 6619267',
    email: 'bhaktapur@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Rabi',
    name: 'Nagendra Lawati',
    image: gurkha,
    province: 'Province 1',
    phone: '024-412107, 412108',
    email: 'rabi@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Narayanghat',
    name: 'Ratna Bahadur Thapa',
    image: ratnaBahadurThapa,
    province: 'Bagmati Province',
    phone: '056-523850',
    email: 'narayangadh@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Janagal',
    name: 'Shova Bajracharya',
    image: shovaBajracharya,
    province: 'Bagmati Province',
    phone: '011-663148',
    email: 'janagal@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Chaumala',
    name: 'Shanti Singh',
    image: shantiSingh,
    province: 'Sudurpashchim Province',
    phone: '091-418052',
    email: 'chaumala@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Bijauri',
    name: 'Ram Bahadur Rawal',
    image: ramBahadurRawal,
    province: 'Lumbini Province',
    phone: '082-411126',
    email: 'bijauri@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Padsari',
    name: 'Manish Acharya',
    image: manishAcharya,
    province: 'Lumbini Province',
    phone: '071-429346',
    email: 'lakhanchowk@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Bhedetar',
    name: 'Suman Subba',
    image: sumanSubba,
    province: 'Province 1',
    phone: '025-400064',
    email: 'bhedetar@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Chhapradi',
    name: 'Uma Raj Gurung',
    image: umaRajGurung,
    province: 'Madhesh Province',
    phone: '9852831520',
    email: 'chapradi@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Pokhara',
    name: 'Harati Mathema',
    image: harathiMathema,
    province: 'Gandaki Province',
    phone: '061-541984',
    email: 'pokhara@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Bhojpur',
    name: 'Suraj Rai',
    image: surajRaiBhojpur,
    province: 'Province 1',
    phone: '9851212379, 9813812307',
    email: 'bhojpur@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Surkhet',
    name: 'Tap Bahadur Khadka',
    image: tapBahadurKhadka,
    province: 'Karnali Province',
    phone: '083-524936',
    email: 'surkhet@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Nepalgunj',
    name: 'Giridhar Shahi',
    image: giridharShahi,
    province: 'Lumbini Province',
    phone: '081-535003, 081-535004',
    email: 'nepalgunj@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Birgunj',
    name: 'Pramod Kumar Jha',
    image: pramodKumar,
    province: 'Madhesh Province',
    phone: '977 051-531479, 534479',
    email: 'birgunj@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Gongabu',
    name: 'Biswash Khadka',
    image: bishwashKhadka,
    province: 'Bagmati Province',
    phone: ' 01-4964132,4383708',
    email: 'gongabu@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Beltar',
    name: 'Jiban Kaji Karki',
    image: jiwanKajiKarki,
    province: 'Province 1',
    phone: '035-440053, 9849322391',
    email: 'beltar@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Hetauda',
    name: 'Rajan Silpakar',
    image: rajan,
    province: 'Bagmati Province',
    phone: ' 057- 590524, 057- 590525, 9841185539',
    email: 'hetauda@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Barahathawa',
    name: ' Dilip Kumar Mandal',
    image: dilipKumarMandal,
    province: 'Madhesh Province',
    phone: '046-540248, 9844107612, 9817800111',
    email: 'barahathawa@gurkhasfinance.com.np',
  },
  {
    OfficeLocation: 'Koteshwor Exension Counter',
    name: 'Prasanna Subba Pomo',
    image: prasannaSubba,
    province: 'Bagmati Province',
    phone: '01-4610764, 4610765',
    email: '',
  },
]
