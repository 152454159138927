import React from "react";

export const Table = ({ tableHeader, tableData }) => {
  //   console.log(tableData);
  return (
    <table>
      <thead>
        <tr>
          {tableHeader &&
            tableHeader.map((head, index) => {
              return <>
                {head === "Institutional (Renew)" ? <th key={index}>Institutional<br />(Renew)</th> : <th key={index}>{head}</th>}
              </>
            })}
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((rowData, index) => {
            return (
              <tr key={index}>
                {rowData.map((tableItem, index) => {
                  if (
                    tableItem ===
                    "Rs. 300/- Individual Account Rs. 350/- Institutional Account"
                  ) {
                    return (
                      <td key={index}>
                        Rs. 300/- Individual Account <br />
                        Rs. 350/- Institutional Account
                      </td>
                    );
                  } else if (
                    tableItem ===
                    "Rs. 250/- Individual Account Rs. 300/- Institutional Account"
                  ) {
                    return (
                      <td key={index}>
                        Rs. 250/- Individual Account <br />
                        Rs. 300/- Institutional Account
                      </td>
                    );
                  } else if (
                    tableItem === "1 to 1000 = Rs. 10/- 1001 to 5000 = Rs. 20/-"
                  ) {
                    return (
                      <td key={index}>
                        1 to 1000 = Rs. 10/- <br />
                        1001 to 5000 = Rs. 20/-
                      </td>
                    );
                  }
                  return <td key={index}>{tableItem}</td>;
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
