import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import gurkhasLogo from "../../images/gurkhas-logo.png";
import menu from "../../images/hamburger.png";

const HeaderBottom = () => {
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const body = document.querySelector("body");
    showMenu ? body.classList.add("show") : body.classList.remove("show");
  }, [showMenu]);
  return (
    <div className="header-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="icon-container">
              <Link to="/">
                <img src={gurkhasLogo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="bottom-right">
              <div
                className="hamburger-logo-container"
                onClick={() => setShowMenu(!showMenu)}
              >
                <img src={menu} alt="" />
              </div>
              <div className={`nav ${showMenu ? "show" : ""}`}>
                <div className="navlist">
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <p>
                        {" "}
                        About
                        <br />
                        Gurkhas
                      </p>
                      <div className="top-arrow"></div>
                    </div>
                    <div className="drop-down-menu">
                      <ul>
                        <li className="drop-down-item">
                          <Link to="/introduction" onClick={() => setShowMenu(false)}>Introduction</Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="bod" onClick={() => setShowMenu(false)}>Board Of Directors</Link>
                        </li>
                        <li className="drop-down-item">
                          <div
                            className="drop-down-arrow"
                            style={{ gap: "0px" }}
                          >
                            <p>Board Levels Committee</p>
                            <div className="item-arrow"></div>
                          </div>
                          <div className="sub-menu sub-menu-small">
                            <ul>
                              <li className="sub-item">
                                <Link to="blc/audit-committee" onClick={() => setShowMenu(false)}>
                                  Audit Committee
                                </Link>
                              </li>
                              <li className="sub-item">
                                <Link to="blc/risk-management" onClick={() => setShowMenu(false)}>
                                  Risk Management
                                </Link>
                              </li>
                              <li className="sub-item">
                                <Link to="blc/staff-facilities" onClick={() => setShowMenu(false)}>
                                  Staff Facilities
                                </Link>
                              </li>
                              <li className="sub-item">
                                <Link to="blc/aml-cft-committee" onClick={() => setShowMenu(false)}>
                                  AML-CFT Committee
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="drop-down-item">
                          <div
                            className="drop-down-arrow"
                            style={{ gap: "0px" }}
                          >
                            <p>Management Team</p>
                            <div className="item-arrow"></div>
                          </div>
                          <div className="sub-menu sub-menu-small">
                            <ul>
                              <li className="sub-item">
                                <Link to="mt/executive-level" onClick={() => setShowMenu(false)}>
                                  Executive Level
                                </Link>
                              </li>
                              <li className="sub-item">
                                <Link to="mt/head-of-department" onClick={() => setShowMenu(false)}>
                                  Head of Department
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/ci/company-secretary" onClick={() => setShowMenu(false)}>
                            Company Secretary
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/ci/information-officers" onClick={() => setShowMenu(false)}>
                            Information Officer
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/ci/grievance-handling-officer" onClick={() => setShowMenu(false)}>
                            Grievance Handling Ofiicer
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/branch-manager" onClick={() => setShowMenu(false)}>Branch Managers</Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/CapitalShareHolder" onClick={() => setShowMenu(false)}>
                            Capital Structure
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/promoter-shareholder" onClick={() => setShowMenu(false)}>
                            Promoter Shareholders
                          </Link>
                        </li>
                        {/* <li className="drop-down-item">
                          <Link to="/table/shareholder-structure">
                            Shareholder Structure
                          </Link>
                        </li> */}
                        <li className="drop-down-item">
                          <Link to="/table/banking-hours" onClick={() => setShowMenu(false)}>Banking Hour</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <p>
                        {" "}
                        Explore
                        <br />
                        Products
                      </p>
                      <div className="top-arrow"></div>
                    </div>
                    <div className="drop-down-menu">
                      <ul>
                        <li className="drop-down-item">
                          <div className="drop-down-arrow">
                            <icon className="icon deposit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="20px"
                                height="20px"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 16 16"
                              >
                                <g fill="#aaa">
                                  <path d="M5 6.25a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267c.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431c.261-.11.508-.266.705-.444c.315.306.815.306.815-.417c0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255a.7.7 0 0 0-.202-.645a.58.58 0 0 0-.707-.098a.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733c3.146 0 5.536 2.114 5.536 4.542c0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359a.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534a4.649 4.649 0 0 1-1.284-1.541a.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21a.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z"
                                  />
                                </g>
                              </svg>
                            </icon>
                            <p>Deposit</p>
                            <div className="item-arrow"></div>
                          </div>
                          <div className="sub-menu">
                            <ul>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Saving</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>
                                <div className="second-sub-menu">
                                  <ul>
                                    {/* <li className="second-sub-item">
                                      <Link to="/saving/gurkhas-saving">
                                        Gurkhas Saving
                                      </Link>
                                    </li> */}
                                    <li className="second-sub-item">
                                      <Link to="/saving/nari-bachat" onClick={() => setShowMenu(false)}>
                                        Nari Bachat
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/bal-bachat" onClick={() => setShowMenu(false)}>
                                        Bal Bachat
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/regular-saving" onClick={() => setShowMenu(false)}>
                                        Regular Saving
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/payroll-saving" onClick={() => setShowMenu(false)}>
                                        Payroll Savings
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/couple-saving" onClick={() => setShowMenu(false)}>
                                        Couple Saving
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/welfare-saving" onClick={() => setShowMenu(false)}>
                                        Welfare Saving
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/student-saving" onClick={() => setShowMenu(false)}>
                                        Student Savings
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/non-profitable-org" onClick={() => setShowMenu(false)}>
                                        Non-profitable org. saving
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/jesthanagarik" onClick={() => setShowMenu(false)}>
                                        Jestha Nagarik
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/shareholder-saving" onClick={() => setShowMenu(false)}>
                                        Shareholders Savings
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/staff-saving" onClick={() => setShowMenu(false)}>
                                        Staff Saving
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/gurkha-pension-saving" onClick={() => setShowMenu(false)}>
                                        Gurkha Pension Savings
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/special-saving" onClick={() => setShowMenu(false)}>
                                        Special Savings
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/saving/remittance-saving" onClick={() => setShowMenu(false)}>
                                        Remittance Saving
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Fixed Deposit</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>
                                <div className="second-sub-menu">
                                  <ul>
                                    {/* <li className="second-sub-item">
                                      <Link to="/fixed-deposit/3-month">
                                        3 Months
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/fixed-deposit/6-month">
                                        6 Months
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/fixed-deposit/1-year">
                                        1 Year
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/fixed-deposit/2-years">
                                        2 Year
                                      </Link>
                                    </li> */}
                                    <li className="second-sub-item">
                                      <Link to="/fixed-deposit/individual" onClick={() => setShowMenu(false)}>
                                        Individual Fixed Deposit
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/fixed-deposit/institutional" onClick={() => setShowMenu(false)}>
                                        Institutional Fixed Deposit
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Other Deposit Product</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>

                                <div className="second-sub-menu">
                                  <ul>
                                    <li className="second-sub-item">
                                      <Link to="/other-deposit/current-deposit" onClick={() => setShowMenu(false)}>
                                        Current Deposit
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/other-deposit/call-deposit" onClick={() => setShowMenu(false)}>
                                        Call Deposit
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="drop-down-item">
                          <div className="drop-down-arrow">
                            <icon className="icon loan">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="20"
                                height="20"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill="#aaa"
                                  d="M7.954 1.372a1 1 0 0 1 1.414-.15l3.262 2.664a1 1 0 0 1 .25 1.245A3 3 0 0 0 12 5h-.3l.298-.34l-1.718-1.403l-1.417 1.744H7.574l1.931-2.376l-.77-.629L6.337 5h-1.28l2.897-3.628ZM10.5 10a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1ZM3 5.5a.5.5 0 0 1 .5-.5h.558l.795-1H3.5A1.5 1.5 0 0 0 2 5.5v6A2.5 2.5 0 0 0 4.5 14H12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H3.5a.5.5 0 0 1-.5-.5Zm0 6V6.915c.156.055.325.085.5.085H12a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4.5A1.5 1.5 0 0 1 3 11.5Z"
                                />
                              </svg>
                            </icon>
                            <p>Loan and Advance</p>
                            <div className="item-arrow"></div>
                          </div>
                          <div className="sub-menu">
                            <ul>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Retail Loan</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>
                                <div className="second-sub-menu">
                                  <ul>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/mortgage-loan" onClick={() => setShowMenu(false)}>
                                        Mortgage Loan
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/auto-loan" onClick={() => setShowMenu(false)}>
                                        Auto Loan
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/professional-loan" onClick={() => setShowMenu(false)}>
                                        Professional Loan
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/gold-loan" onClick={() => setShowMenu(false)}>
                                        Gold Loan
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/margin-lending" onClick={() => setShowMenu(false)}>
                                        Margin Lending
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/retail-loan/loan-fdr" onClick={() => setShowMenu(false)}>
                                        Loan against FDR
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Business Loan</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>
                                <div className="second-sub-menu">
                                  <ul>
                                    <li className="second-sub-item">
                                      <Link to="/business-loan/sme-loan" onClick={() => setShowMenu(false)}>
                                        SME Loan
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/business-loan/non-funded-credit" onClick={() => setShowMenu(false)}>
                                        Non Funded Credit Facilities
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="sub-item">
                                <div className="drop-down-arrow">
                                  <p>Micro Loan</p>
                                  <div className="sub-menu-arrow"></div>
                                </div>
                                <div className="second-sub-menu">
                                  <ul>
                                    <li className="second-sub-item">
                                      <Link to="/micro-loan/laghu-karja" onClick={() => setShowMenu(false)}>
                                        Laghu Karja
                                      </Link>
                                    </li>
                                    <li className="second-sub-item">
                                      <Link to="/micro-loan/dsl-auto-rickshaw" onClick={() => setShowMenu(false)}>
                                        DSL Auto Rickshwa
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <p>
                        {" "}
                        Transactional
                        <br />
                        Banking
                      </p>
                      <div className="top-arrow"></div>
                    </div>
                    <div className="drop-down-menu">
                      <ul>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/e-banking" onClick={() => setShowMenu(false)}>
                            E-Banking
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/mobile-banking" onClick={() => setShowMenu(false)}>
                            Mobile Banking
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/qr-scan-pay" onClick={() => setShowMenu(false)}>
                            QR Scan and Pay
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/debit-card" onClick={() => setShowMenu(false)}>
                            Debit Card
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/remit-services" onClick={() => setShowMenu(false)}>
                            Remit Service
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/locker-services" onClick={() => setShowMenu(false)}>
                            Locker Service
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/asba" onClick={() => setShowMenu(false)}>
                            ASBA/CASBA Service
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/transactional-banking/demat" onClick={() => setShowMenu(false)}>DEMAT</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <p>
                        {" "}
                        Investor
                        <br />
                        Relation
                      </p>
                      <div className="top-arrow"></div>
                    </div>
                    <div className="drop-down-menu">
                      <ul>
                        <li className="drop-down-item">
                          <div className="drop-down-arrow">
                            <icon className="icon report-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="20"
                                height="20"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="#aaa"
                                  d="M10 18h8v2h-8zm0-5h12v2H10zm0 10h5v2h-5z"
                                />
                                <path
                                  fill="#aaa"
                                  d="M25 5h-3V4a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v1H7a2 2 0 0 0-2 2v21a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2ZM12 4h8v4h-8Zm13 24H7V7h3v3h12V7h3Z"
                                />
                              </svg>
                            </icon>
                            <p>Report</p>
                            <div className="item-arrow"></div>
                          </div>
                          <div className="sub-menu sub-menu-small">
                            <ul>
                              <li className="sub-item">
                                <Link to="/quarterly-report" onClick={() => setShowMenu(false)}>
                                  Quarterly Report
                                </Link>
                              </li>
                              <li className="sub-item">
                                <Link to="/annual-report" onClick={() => setShowMenu(false)}>Annual Report</Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/agm-minute" onClick={() => setShowMenu(false)}>AGM Minute</Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/basel-disclosure" onClick={() => setShowMenu(false)}>Basel Disclosure</Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/major-financial-highlight">
                            Major Financial Highlights
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/price-sensitive-information" onClick={() => setShowMenu(false)}>
                            Price Sensitive Information
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/right-to-information" onClick={() => setShowMenu(false)}>
                            Right to Information
                          </Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/base-net-interest" onClick={() => setShowMenu(false)}>
                            Base Rate & Net Spread Interest Rate
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <p>
                        {" "}
                        Branches
                        <br />& ATM Outlets
                      </p>
                      <div className="top-arrow"></div>
                    </div>
                    <div className="drop-down-menu">
                      <ul>
                        <li className="drop-down-item">
                          <Link to="/branches&atm/branches" onClick={() => setShowMenu(false)}>Branches</Link>
                        </li>
                        <li className="drop-down-item">
                          <Link to="/branches&atm/atm" onClick={() => setShowMenu(false)}>ATM</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="navlist-items">
                    <div className="drop-down-arrow">
                      <Link to="/tariffs" style={{ color: "#212529" }} onClick={() => setShowMenu(false)}>
                        <p>
                          Tariffs
                          <br />& Charges
                        </p>
                      </Link>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
