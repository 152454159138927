import React from "react";
import { Link } from "react-router-dom";

const RelatedProduct = ({ pageId }) => {
  return (
    <div className="related-products">
      <div className="header-box">
        <div className="title" style={{ marginBottom: "35px" }}>
          <p>
            <strong>Related Products</strong>
          </p>
        </div>
      </div>
      <ul>
        {pageId !== "e-banking" && (
          <li>
            <Link to="/transactional-banking/e-banking">E-Banking</Link>
          </li>
        )}
        {pageId !== "mobile-banking" && (
          <li>
            <Link to="/transactional-banking/mobile-banking">
              Mobile Banking
            </Link>
          </li>
        )}
        {pageId !== "qr-scan-pay" && (
          <li>
            <Link to="/transactional-banking/qr-scan-pay">QR Scan and Pay</Link>
          </li>
        )}
        {pageId !== "debit-card" && (
          <li>
            <Link to="/transactional-banking/debit-card">Debit Card</Link>
          </li>
        )}
        {pageId !== "remit-services" && (
          <li>
            <Link to="/transactional-banking/remit-services">
              Remit Services
            </Link>
          </li>
        )}
        {pageId !== "locker-services" && (
          <li>
            <Link to="/transactional-banking/locker-services">
              Locker Services
            </Link>
          </li>
        )}
        {pageId !== "asba" && (
          <li>
            <Link to="/transactional-banking/asba">ASBA/C-ASBA Service</Link>
          </li>
        )}
        {pageId !== "demat" && (
          <li>
            <Link to="/transactional-banking/demat">DEMAT</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default RelatedProduct;
