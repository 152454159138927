import React from "react";
import { useParams } from "react-router-dom";
import mapdatas from "./GridViewData.json"
export default function SideBar(mapData) {
  const { id } = useParams();
  console.log(mapData.mapData,"dd");
  return (
    <>
      {id === "branches" && 
      
        <div className="card-wrapper">
          {mapdatas && mapdatas[0].branch.map((item)=>
          <>{
            item.branchName == mapData.mapData &&
            <div className="address-card">
            <p className="caption-big">{item.branchName}</p>
            <p className="small-light-text">
        {item.branchLocation}
            </p>
            <p className="small-light-text">Phone : {item.phone}</p>
            <p className="small-light-text">
              Email :{item.email}
            </p>
            <li className="misc-link">
              <a href={item.link} target="_blank">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 8.66602C7.10457 8.66602 8 7.77059 8 6.66602C8 5.56145 7.10457 4.66602 6 4.66602C4.89543 4.66602 4 5.56145 4 6.66602C4 7.77059 4.89543 8.66602 6 8.66602Z"
                    stroke="#FF0001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M5.99984 1.33301C4.58535 1.33301 3.2288 1.89491 2.2286 2.8951C1.22841 3.8953 0.666504 5.25185 0.666504 6.66634C0.666504 7.92767 0.934504 8.75301 1.6665 9.66634L5.99984 14.6663L10.3332 9.66634C11.0652 8.75301 11.3332 7.92767 11.3332 6.66634C11.3332 5.25185 10.7713 3.8953 9.77107 2.8951C8.77088 1.89491 7.41433 1.33301 5.99984 1.33301V1.33301Z"
                    stroke="#FF0001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                View Direction
              </a>
            </li>
          </div>
          }

          </>

          )

          }                

      
        </div>
      }
      {id === "atm" && (
        <div className="card-wrapper">

          {mapdatas && mapdatas[1].atm.map((item)=>
          <>
          {item.branchName == mapData.mapData &&
                     <div className="address-card">
                     <p className="caption-big">ATM Location</p>
                     <p className="small-light-text">
                     {item.branchName}
                     </p>
                     <p className="small-light-text">Phone :{item.phone}</p>
                     <p className="small-light-text">
                       Email :{item.email}
                     </p>
                     <li className="misc-link">
                       <a href={item.link} target={"_blank"}>
                         <svg
                           width="12"
                           height="16"
                           viewBox="0 0 12 16"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                         >
                           <path
                             d="M6 8.66602C7.10457 8.66602 8 7.77059 8 6.66602C8 5.56145 7.10457 4.66602 6 4.66602C4.89543 4.66602 4 5.56145 4 6.66602C4 7.77059 4.89543 8.66602 6 8.66602Z"
                             stroke="#FF0001"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                           ></path>
                           <path
                             d="M5.99984 1.33301C4.58535 1.33301 3.2288 1.89491 2.2286 2.8951C1.22841 3.8953 0.666504 5.25185 0.666504 6.66634C0.666504 7.92767 0.934504 8.75301 1.6665 9.66634L5.99984 14.6663L10.3332 9.66634C11.0652 8.75301 11.3332 7.92767 11.3332 6.66634C11.3332 5.25185 10.7713 3.8953 9.77107 2.8951C8.77088 1.89491 7.41433 1.33301 5.99984 1.33301V1.33301Z"
                             stroke="#FF0001"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                           ></path>
                         </svg>
                         View Direction
                       </a>
                     </li>
                   </div>
          
          }
       
          </>
)}

        </div>
      )}
    </>
  );
}
