import React from 'react'
import gaurabBudathoki from "../../images/GAURAB-BUDATHOKI.jpg"
import dekindra from "../../images/dekindra-sir.jpg"
import karnaBahadurRai from "../../images/Karna-Bahadur-Rai-updated.jpg"
import kalpanaKhapung from "../../images/Kalpana-Khapung-Updated(3).jpg"
import NarendraKumarAgrawal from "../../images/Narendra-Kumar-Agrawal-updated.jpg"
import NeelamBohara from "../../images/Neelam-Bohara-updated.jpg"
import SagarSharma from "../../images/Sagar-Sharma-updated(1).jpg"

export default function Gallery() {
    return (
        <section class="section-gallery">
            <div class="container">
                <div class="content">
                    <div class="row-single">
                        {/* <div class="card-person">
                            <div class="image-container">
                                <img src={gaurabBudathoki} alt="Gaurab Budathoki" />
                            </div>
                            <p class="caption-big">Gaurab Budhathoki</p>
                            <p class="caption-small">Chairman</p>
                        </div> */}
                        <div class="card-person">
                            <div class="image-container">
                                <img src={dekindra} alt="Dekindra Kumar Rai" />
                            </div>
                            <p class="caption-big">Dekindra Kumar Rai</p>
                            <p class="caption-small">Chairman</p>
                        </div>
                    </div>
                    <div class="row-multiple">


                        <div class="card-person">
                            <div class="image-container">
                                <img src={karnaBahadurRai} alt="Karan Bahadur Rai" />
                            </div>
                            <p class="caption-big">Karna Bahadur Rai</p>
                            <p class="caption-small">Director (Promoter Group)</p>
                        </div>
                        <div class="card-person">
                            <div class="image-container">
                                <img src={kalpanaKhapung} alt="Kalpana Khapung" />
                            </div>
                            <p class="caption-big">Kalpana Khapung</p>
                            <p class="caption-small">Director (Promoter Group)</p>
                        </div>
                        <div class="card-person">
                            <div class="image-container">
                                <img src={gaurabBudathoki} alt="Gaurab Budathoki" />
                            </div>
                            <p class="caption-big">Gaurab Budhathoki</p>
                            <p class="caption-small">Director (Public)</p>
                        </div>
                        <div class="card-person">
                            <div class="image-container">
                                <img src={NarendraKumarAgrawal} alt="Narendra Kumar Agrawal" />
                            </div>
                            <p class="caption-big">Narendra Kumar Agrawal</p>
                            <p class="caption-small">Director (Public)</p>
                        </div>

                        <div class="card-person">
                            <div class="image-container">
                                <img src={NeelamBohara} alt="Nilam Bohara" />
                            </div>
                            <p class="caption-big">Neelam Bohara</p>
                            <p class="caption-small">Director (Public)</p>
                        </div>
                        <div class="card-person">
                            <div class="image-container">
                                <img src={SagarSharma} alt="" />
                            </div>
                            <p class="caption-big">Sagar Sharma</p>
                            <p class="caption-small">Director (Independent)</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
