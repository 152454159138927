import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import GridView from "./GridView";
import SideBar from "./SideBar";
import SecondaryBanner from "../../Components/SecondaryBanner";
// import Contact from "../../Components/Contact";
import Maps from "./Maps";

export default function BranchesAtm() {
  const [gridView, setGridView] = useState(true);
  const [bannerData, setBannerData] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [optionValue, setOptionValue] = useState("all over nepal");

  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (id === "branches") {
      document.title = "Branches";
      setBannerData({
        breadcrumb: ["Home", "Branches & ATMs Outlets", "Branch"],
        bannerTitle: "Branch",
      });
    }
    if (id === "atm") {
      document.title = "ATM";
      setBannerData({
        breadcrumb: ["Home", "Branches & ATMs Outlets", "ATM"],
        bannerTitle: "ATM",
      });
    }
  }, [id]);
  console.log(optionValue, "optionValue");


  return (
    <>
      {bannerData && <SecondaryBanner bannerData={bannerData} />}
      <section className="section-sub-header">
        <div className="container">
          <div className="content">

            <div className="location-dropdown">
              {gridView &&
                <select onClick={(e) => setOptionValue(e.target.value)}>
                  <option value="all over nepal">All Over Nepal</option>
                  <option value="1">Province No 1</option>
                  <option value="madesh">Madhesh Province</option>
                  <option value="bagmati">Bagmati Province</option>
                  <option value="gandaki">Gandaki Province</option>
                  <option value="lumbini">Lumbini Province</option>
                  <option value="karnali">Karnali Province</option>
                  <option value="sudurpashchim">Sudurpashchim Province</option>
                </select>}
            </div>

            <div className="button-container">
              <button
                className={
                  gridView === false
                    ? "btn btn-tertiary active-view"
                    : "btn btn-tertiary"
                }
                id="map"
                onClick={() => setGridView(false)}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.8399 13.9013C13.89 13.8602 13.9303 13.8085 13.958 13.75C13.9857 13.6914 14 13.6274 14 13.5627V1.31265C13.9999 1.21155 13.9649 1.11359 13.9007 1.03543C13.8366 0.957262 13.7474 0.903721 13.6482 0.883904L9.27325 0.00890446C9.21664 -0.00236416 9.15836 -0.00236416 9.10175 0.00890446L4.8125 0.866405L0.52325 0.00890446C0.459803 -0.0037775 0.394332 -0.00222874 0.331556 0.0134401C0.268779 0.029108 0.210259 0.058506 0.160212 0.099514C0.110165 0.140523 0.0698367 0.192121 0.0421328 0.250592C0.0144289 0.309064 3.90711e-05 0.372952 0 0.437654L0 12.6877C6.10593e-05 12.7888 0.0351366 12.8867 0.099263 12.9649C0.163389 13.043 0.252608 13.0966 0.35175 13.1164L4.72675 13.9914C4.78336 14.0027 4.84164 14.0027 4.89825 13.9914L9.1875 13.1339L13.4768 13.9914C13.5402 14.0042 13.6056 14.0027 13.6684 13.9871C13.7312 13.9715 13.7898 13.9422 13.8399 13.9013ZM8.75 12.3289L5.25 13.0289V1.6714L8.75 0.971404V12.3289ZM9.625 0.971404L13.125 1.6714V13.0289L9.625 12.3289V0.971404ZM4.375 1.6714V13.0289L0.875 12.3289V0.971404L4.375 1.6714Z"
                    fill="#4F4F4F"
                  ></path>
                </svg>
                Map View
              </button>
              <button
                className={
                  gridView ? "btn btn-tertiary active-view" : "btn btn-tertiary"
                }
                id="grid"
                onClick={() => setGridView(true)}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15V11C2 10.7348 2.10536 10.4804 2.29289 10.2929C2.48043 10.1054 2.73478 10 3 10H7C7.26522 10 7.51957 10.1054 7.70711 10.2929C7.89464 10.4804 8 10.7348 8 11V15C8 15.2652 7.89464 15.5196 7.70711 15.7071C7.51957 15.8946 7.26522 16 7 16ZM3 11V15H7V11H3Z"
                    fill="#4F4F4F"
                  ></path>
                  <path
                    d="M15 16H11C10.7348 16 10.4804 15.8946 10.2929 15.7071C10.1054 15.5196 10 15.2652 10 15V11C10 10.7348 10.1054 10.4804 10.2929 10.2929C10.4804 10.1054 10.7348 10 11 10H15C15.2652 10 15.5196 10.1054 15.7071 10.2929C15.8946 10.4804 16 10.7348 16 11V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16ZM11 11V15H15V11H11Z"
                    fill="#4F4F4F"
                  ></path>
                  <path
                    d="M7 8H3C2.73478 8 2.48043 7.89464 2.29289 7.70711C2.10536 7.51957 2 7.26522 2 7V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H7C7.26522 2 7.51957 2.10536 7.70711 2.29289C7.89464 2.48043 8 2.73478 8 3V7C8 7.26522 7.89464 7.51957 7.70711 7.70711C7.51957 7.89464 7.26522 8 7 8ZM3 3V7H7V3H3Z"
                    fill="#4F4F4F"
                  ></path>
                  <path
                    d="M15 8H11C10.7348 8 10.4804 7.89464 10.2929 7.70711C10.1054 7.51957 10 7.26522 10 7V3C10 2.73478 10.1054 2.48043 10.2929 2.29289C10.4804 2.10536 10.7348 2 11 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289C15.8946 2.48043 16 2.73478 16 3V7C16 7.26522 15.8946 7.51957 15.7071 7.70711C15.5196 7.89464 15.2652 8 15 8ZM11 3V7H15V3H11Z"
                    fill="#4F4F4F"
                  ></path>
                </svg>
                Grid View
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="section-branch-atm">
        <div class="container">
          <div
            class={
              gridView === false ? "map-view view active-view" : "map-view view"
            }
            id="map"
          >
            <div class="custume-row">
              <div
                className={
                  mapData ? "side-bar-container show" : "side-bar-container"
                }
              >
                <SideBar mapData={mapData} />
              </div>




              {/* <div class="image-container">
                  <img src="./images/map.png" alt="" />
                </div> */}
              <div className="map-container" style={{ position: 'relative', width: '100%', height: '100%' }}>
                <Maps setMapData={setMapData} />

              </div>
            </div>
          </div>
          <div
            class={gridView ? "grid-view view active-view" : "grid-view view"}
            id="grid"
          >
            <div class="card-row">
              <GridView optionValue={optionValue} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
