import React from "react";

const CurrentDeposit = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Current accounts are non-interest bearing accounts in which the
            customer can deposit the fund for an unspecified period of time. In
            this type of account, the customer can withdraw and transfer the
            fund whenever required. These days one of the main focus areas of
            banks is to increase the portfolio of the current account. Current
            Accounts are one of the profitable sources of deposits to any Bank.
            ln all types of deposits (i.e. fixed, savings, and call deposits),
            the bank has to provide interest whereas for current accounts it is
            interest-free and the bank does not have to provide any interest to
            the customers. Any individuals, firms, institutions, and companies
            can open the current account as their operational account. (Current
            account of any firms, institutions, and companies are also avenues
            for salary saving account.)
          </p>
        </div>
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default CurrentDeposit;
