import React from 'react'
import { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import SecondaryBanner from '../../Components/SecondaryBanner'
import CardDownload from '../../Components/CardDownload'
import { Table } from '../../Components/Table'
const InterestRate = () => {
  useEffect(() => {
    document.title = 'Interest Rates'
    window.scrollTo(0, 0)
  }, [])
  const bannerData = {
    breadcrumb: ['Home', 'Interest Rates'],
    bannerTitle: 'Interest Rates',
  }
  const tableHeaerFD = [
    'Particulars',
    'Individual',
    'Individual (Remit)',
    'Institutional',
    'Institutional (Bidding)',
  ]
  const tableDataFD = [
    ['3 Months', '12.10%', '13.10%', '10.10%', '10.60%'],
    ['6 Months', '12.20%', '13.20%', '10.20%', '10.70%'],
    ['9 Months', '12.25%', '13.25%', '10.25%', '10.75%'],
    ['1 Year to below 2 years', '12.35%', '13.35%', '10.35%', '10.85%'],
    ['2 Years to below 3 years', '12.50%', '13.50%', '10.50%', '11.00%'],
    ['3 Years and above', '13.00%', '14.00%', '11.00%', '11.50%'],
  ]
  const tableHeaderSaving = [
    'Saving Deposits',
    'Minimum Balance Rs.',
    'Interest Rate',
  ]
  const tableDataSaving = [
    ['Bal Bachat', '100.00', '8.00%'],
    ['Regular Saving', '200.00', '8.00%'],
    ['Payroll Savings', '0.00', '8.00%'],
    ['Couple Saving', '200.00', '8.00%'],
    ['Welfare Saving', '0.00', '8.00%'],
    ['Student Savings', '200.00', '8.00%'],
    ['Non-Profitable Org. Saving', '1,000.00', '8.00%'],
    ['Jestha Nagarik', '250.00', '8.00%'],
    ['Shareholders Savings', '200.00', '8.00%'],
    ['Staff Savings', '200.00', '8.00%'],
    ['Nari Bachat', '250.0', '8.00%'],
    ['Gurkhas Pension Saving', '5,000.00', '9.00%'],
    ['Special Savings', '5,000.00', '9.00%'],
    ['Remittance Saving', '200.00', '10.00%'],
    ['Corporate Flexible Saving', '1,000.00', 'Up to 4.00%'],
  ]
  const tableHeaderLoan = ['Loans and Advances', 'Interest Rate %']
  const tableDataLoan = [
    ['Home Loan', 'BR + Upto 7%'],
    ['Personal Loan(OD/Term)', 'BR + Upto 7%'],
    ['Share Loan', 'BR + Upto 7%'],
    ['Gold Loan', 'BR + Upto 7%'],
    ['Business/Working Capital Loan', 'BR + Upto 7%'],
    ['Agriculture/SME/Education Loan', 'BR + Upto 7% '],
    ['Small Farm Individual Loan', 'BR + Upto 7%'],
    ['Hire Purchase', 'BR + Upto 7%'],
    ['Real Estate Loan', 'BR + Upto 7%​'],
    ['Professional Loan/Bridge Gap Loan', 'BR + Upto 7%​'],
    ['Loan Against FD', 'Coupan rate + Up to 2 % or BR whichever is higher'],
    ['Consortium Loan', 'As per Consortium Decision'],
  ]
  const tableHeaderLending = ['Deprive Sector Lending', 'Interest Rate %']
  const tableDataLending = [
    ['Indirect Lending (Wholesale or Institutional)', 'BR + Upto 7%​'],
    ['Direct lending (All Types)', 'BR + Upto 7%​'],
    ['Base Rate of Mangsir end, 2079', '12.71%'],
    ['First Quarter Average Base Rate', '12.66%'],
    ['Spread Rate (2079 Mangsir)', '4.89%'],
  ]
  const tableHeaderFixedInterest = [
    'Fixed Interest Rate Loan (for individual)',
    'Upto 5 Years',
    '5 to 10 Years',
    'Above 10 Years',
  ]
  const tableDataFixedInterest = [
    ['Home Loan', '17.00%', '17.50%', '18.00%'],
    ['Auto Loan/HP Loan', '17.00%', '17.50%', '-'],
    ['Other Retail Loan', '17.00%', '17.50%', '18.00%'],
  ]
  const cardData = {
    year2078: [
      {
        id: 1,
        title: 'Baisakh 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Interest Rate  Baisakh 2078.pdf',
        year: '2078/79',
      },
      {
        id: 2,
        title: 'Shrawan 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Shrawan 2078_3x25cc.pdf',
        year: '2078/79',
      },
      {
        id: 3,
        title: 'Bhadra 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Bhadra Interest Rate 3x25cc,2078.pdf',
        year: '2078/79',
      },
      {
        id: 4,
        title: 'Ashoj 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Interest rate for the month of Ashoj 2078.pdf',
        year: '2078/79',
      },
      {
        id: 5,
        title: 'kartik 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/kartik ,078.pdf',
        year: '2078/79',
      },
      {
        id: 6,
        title: 'Mangsir 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Mamngsir  Interest Rate 3x20cc,078.pdf',
        year: '2078/79',
      },
      {
        id: 7,
        title: 'Poush 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Poush 3x20cc,078.pdf',
        year: '2078/79',
      },
      {
        id: 8,
        title: 'Magh 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Magh 2078 3x20cc.pdf',
        year: '2078/79',
      },
      {
        id: 9,
        title: 'Falgun 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Falgun,078 3x20cc 078.pdf',
        year: '2078/79',
      },
      {
        id: 10,
        title: 'Chaitra 2078',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Chaitra 078 3x28cc,78.pdf',
        year: '2078/79',
      },
    ],
    year2079: [
      {
        id: 1,
        title: 'Jeshtha 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/JESTHA 079.pdf',
        year: '2078/79',
      },
      {
        id: 2,
        title: 'Ashad 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Ashad 079 Int. Rate 3x28cc.pdf',
        year: '2078/79',
      },
      {
        id: 3,
        title: 'Shrawan 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Shrawan 3x24cc INT 079.pdf',
        year: '2078/79',
      },
      {
        id: 4,
        title: 'Bhadra 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Bhadra Rate 3x24cc 079.pdf',
        year: '2078/79',
      },
      {
        id: 5,
        title: 'Ashwin 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Ashwin interest rate 2079.pdf',
        year: '2078/79',
      },
      {
        id: 6,
        title: 'Kartik 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Kartik 2079.pdf',
        year: '2078/79',
      },
      {
        id: 7,
        title: 'Mangsir 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Interest rate for the month of Magh 2079 3x24cc.pdf',
        year: '2078/79',
      },
      {
        id: 8,
        title: 'Poush 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/Interest rate  6x24 cc.pdf',
        year: '2078/79',
      },
      {
        id: 9,
        title: 'Magh 2079',
        link:
          'https://gurkhasfinance.com.np/assets/interest rate/month of Poush 2079.pdf',
        year: '2078/79',
      },
    ],
  }
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-table">
        <div className="container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Interest rate from 1st Magh, 2079 (15th January, 2023)
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="text-container"
                  style={{ padding: '10px 68px' }}
                >
                  <h1>Fixed Deposits</h1>
                </div>
                <div className="tables">
                  <Table tableHeader={tableHeaerFD} tableData={tableDataFD} />
                </div>
                <div
                  className="text-container"
                  style={{ padding: '10px 68px' }}
                >
                  <h1>Saving Deposits</h1>
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderSaving}
                    tableData={tableDataSaving}
                  />
                </div>
                <div
                  className="text-container"
                  style={{ padding: '10px 68px' }}
                >
                  <h1>Loans and Advances</h1>
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderLoan}
                    tableData={tableDataLoan}
                  />
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderLending}
                    tableData={tableDataLending}
                  />
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderFixedInterest}
                    tableData={tableDataFixedInterest}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Interest Rate of 2079</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2079.reverse().map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Interest Rate of 2078</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2078.map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </main>
  )
}

export default InterestRate
