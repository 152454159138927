import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import { Table } from "../../Components/Table";

export default function TrariffCharges() {
  useEffect(() => {
    document.title = "Tariffs and Charges";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Tariffs and Charges"],
    bannerTitle: "Tariffs and Charges",
  };
  const chargesHeader = ["Charges", "Rate"];
  const chargesBody = [
    ["Loan Penal Charge", "2 % of Remaining Loan Balance"],
    ["Loan Procesing Charge", "1.25 % of Loan"],
    ["CIB Charge", "As per actual"],
    ["Balance Certificate Charge", "Free"],
    ["Good for payment", "Free"],
    ["Clearing Above Rs. 2 lakhs", "Rs. 30/-"],
    ["Counter Cheque", "Nrs 100"],
    ["Cheque Lost", "Rs. 500/-"],
  ];
  const debitHeader = ["Charges", "Rate"];
  const debitBody = [
    ["Membership Fee", "Free"],
    ["Annual Fee", "Rs. 350/-"],
    ["Balance Inquiry", "Rs. 5/-(SCT Network)"],
    ["Withdraw Charge", "Rs. 20/- (SCT Network)"],
    ["Re-issuance/ Replacement of Card", "Rs. 500/-"],
    ["Repin Charge", "Rs. 200/-"],
    ["Debit Card Blocking Charge", "Rs. 300/-"],
  ];
  const mobileBankingHeader = ["Charges", "Rate"];
  const mobileBankingBody = [
    [
      "Membership Fee",
      "Rs. 300/- Individual Account Rs. 350/- Institutional Account",
    ],
    ["PIN Reset", "Rs. 100/-"],
    ["Mobile No. Change", "Rs. 25/-"],
    [
      "Annual Renew Charge",
      "Rs. 250/- Individual Account Rs. 300/- Institutional Account",
    ],
    [
      "Inter Bank Fund Transfer (IBFT)",
      "1 to 1000 = Rs. 10/- 1001 to 5000 = Rs. 20/-",
    ],
  ];
  /*   useEffect(() => {
    let parentTable = document.querySelector(".two-column");
    if (parentTable) {
      let tableData = parentTable.querySelectorAll(".small-light-text");
      let tableRawArray = [];
      let arraySubSet = [];
      tableData.forEach((item, index) => {
        let numberOfItemsInSubset = 2;
        if (
          arraySubSet.length !== numberOfItemsInSubset &&
          tableData.length - numberOfItemsInSubset > index
        ) {
          arraySubSet.push(item.textContent);
        } else if (
          arraySubSet.length === numberOfItemsInSubset &&
          index + tableRawArray.length <= tableData.length
        ) {
          tableRawArray.push(arraySubSet);
          arraySubSet = [];
          arraySubSet.push(item.textContent);
        } else {
          // if arraySubSet already contains item push into tableRawArray
          if (arraySubSet.length === numberOfItemsInSubset) {
            tableRawArray.push(arraySubSet);
            arraySubSet = [];
          }
          arraySubSet.push(item.textContent);
          // when in last iteration, if arraySubSet if filled to required length, push to tableRawArray
          if (arraySubSet.length === numberOfItemsInSubset) {
            tableRawArray.push(arraySubSet);
          }
        }
      });
      console.log(tableRawArray);
    }
  }, []); */

  return (
    <>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-table">
        <div class="container" style={{ backgroundColor: "white" }}>
          <div className="text-container" style={{ padding: "10px 68px" }}>
            <h1>Charges</h1>
          </div>
          <div class="tables">
            <Table tableHeader={chargesHeader} tableData={[...chargesBody]} />
          </div>
          <div className="text-container" style={{ padding: "10px 68px" }}>
            <h1>Debit Card</h1>
          </div>
          <div className="tables">
            <Table tableHeader={debitHeader} tableData={[...debitBody]} />
          </div>

          <div className="text-container" style={{ padding: "10px 68px" }}>
            <h1>Mobile Banking</h1>
          </div>
          <div className="tables">
            <Table
              tableHeader={mobileBankingHeader}
              tableData={[...mobileBankingBody]}
            />
          </div>
        </div>
      </section>
    </>
  );
}
