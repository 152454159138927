import React from "react";

const IndividualDeposit = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            A fixed deposit is an account that provides a higher interest rate
            than other account products of the bank. Any individual can open a
            fixed deposit. A fixed deposit opened by any individual person is an
            individual fixed deposit. Fixed deposit is open for the specific
            period of time (like 3 months, 6 months, 9 months, 1 year, etc.)
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndividualDeposit;
