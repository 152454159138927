import React from "react";
import { Link } from "react-router-dom";

const RetailRelatedProduct = ({ pageID }) => {
  return (
    <div className="related-products">
      <div className="header-box">
        <div className="title" style={{ marginBottom: "35px" }}>
          <p>
            <strong>Related Products</strong>
          </p>
        </div>
      </div>
      <ul>
        {pageID !== "mortgage-loan" && (
          <li>
            <Link to="/retail-loan/mortgage-loan">Mortgage Loan</Link>
          </li>
        )}
        {pageID !== "auto-loan" && (
          <li>
            <Link to="/retail-loan/auto-loan">Auto Loan</Link>
          </li>
        )}
        {pageID !== "professional-loan" && (
          <li>
            <Link to="/retail-loan/professional-loan">Professional Loan</Link>
          </li>
        )}
        {pageID !== "gold-loan" && (
          <li>
            <Link to="/retail-loan/gold-loan">Gold Loan</Link>
          </li>
        )}
        {pageID !== "margin-lending" && (
          <li>
            <Link to="/retail-loan/margin-lending">Margin Lending</Link>
          </li>
        )}
        {pageID !== "loan-fdr" && (
          <li>
            <Link to="/retail-loan/loan-fdr">Loan Against FDR</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default RetailRelatedProduct;
