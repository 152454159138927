import gaurabBudathoki from '../../images/GAURAB-BUDATHOKI.jpg'
import dekindra from '../../images/dekindra-sir.jpg'
import karnaBahadurRai from '../../images/Karna-Bahadur-Rai-updated.jpg'
import kalpanaKhapung from '../../images/Kalpana-Khapung-Updated(3).jpg'
import narendraKumarAgrawal from '../../images/Narendra-Kumar-Agrawal-updated.jpg'
import neelamBohara from '../../images/Neelam-Bohara-updated.jpg'
import sagarSharma from '../../images/Sagar-Sharma-updated(1).jpg'
import avatar from '../../images/avatar.webp'
import santoshKumarGhimire from '../../images/santosh-kumar-ghimire.jpg'
import mukundaShrestha from '../../images/mukunda shrestha.jpg'
import deepakNeupane from '../../images/deepak-neupane.jpg'
import sujanJoshi from '../../images/sujan-joshi.jpg'
import yanSingRai from '../../images/yan-sing-rai.jpg'
import shambuRai from '../../images/shambu-rai.jpg'
import bharatThapa from '../../images/bharat-thapa.jpg'
import dineshTamang from '../../images/dinesh-tamang.jpg'
import raushan from '../../images/raushan.jpg'
import yogendra from '../../images/yogendra .jpg'
import sahana from '../../images/sahana .jpg'
import sunitaRai from '../../images/sunita-rai.jpg'
import sunena from '../../images/sunena-.jpg'
import shantiThing from '../../images/shanti-thing.jpg'
import subashRai from '../../images/subash-rai.jpg'
import gurkhaLogo from '../../images/gurkha-square-log.png'
export const auditCommittee = [
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Gaurab Budathoki',
    position: 'Coordinator',
    image: gaurabBudathoki,
  },
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Karna Bahadur Rai',
    position: 'Member',
    image: karnaBahadurRai,
  },
  {
    name: 'Head of Internal Audit Department ',
    position: 'Member Secretary',
    image: gurkhaLogo,
  },
]
export const riskManagement = [
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Neelam Bohora',
    position: 'Coordinator',
    image: neelamBohara,
  },
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Gaurab Budathoki',
    position: 'Member',
    image: gaurabBudathoki,
  },
  {
    name: 'Head of Operation Department',
    position: 'Member',
    image: gurkhaLogo,
  },
  {
    name: 'Chief Risk Officer',
    position: 'Member Secretary',
    image: gurkhaLogo,
  },
]
export const staffFacilities = [
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Narendra Kumar Agrawal',
    position: 'Coordinator',
    image: narendraKumarAgrawal,
  },
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Chief Executive Officer',
    position: 'Officer Member',
    image: gurkhaLogo,
  },
  {
    name: 'Chief Finance Officer',
    position: 'Officer Member',
    image: gurkhaLogo,
  },
  {
    name: 'Head, Human Resource Department',
    position: 'Member Secretary',
    image: gurkhaLogo,
  },
]
export const amlCftCommittee = [
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Kalpana Khapung',
    position: 'Coordinator',
    image: kalpanaKhapung,
  },
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Sagar Sharma',
    position: 'Member',
    image: sagarSharma,
  },
  {
    name: 'Chief Risk Officer',
    position: 'Member',
    image: gurkhaLogo,
  },
  {
    name: 'Head, Compliance Department',
    position: 'Member Secretary',
    image: gurkhaLogo,
  },
]
export const executiveLevel = [
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Santosh Kumar Ghimire',
    position: 'CEO',
    image: santoshKumarGhimire,
  },
  {
    name: '',
    position: '',
    image: '',
  },
  {
    name: 'Mukunda Shrestha',
    position: 'AGM / Company Secretary',
    image: mukundaShrestha,
  },
  {
    name: 'Deepak Neupane',
    position: 'Senior Manager',
    image: deepakNeupane,
  },
]
export const headOfDepartment = [
  {
    name: 'Sujan Joshi',
    position: 'Head - IT',
    image: sujanJoshi,
  },
  {
    name: 'Yan Singh Rai',
    position: 'Head - Account and Finance',
    image: yanSingRai,
  },
  {
    name: 'Shambhu Rai',
    position: 'Head - Human Resource & Admin',
    image: shambuRai,
  },
  {
    name: 'Bharat Thapa Chhetri',
    position: 'Head - Debt Recovery',
    image: bharatThapa,
  },
  {
    name: 'Dinesh Tamang',
    position: 'Head - Credit Operation',
    image: dineshTamang,
  },
  {
    name: 'Raushan Kumar Singh',
    position: 'Head - Legal',
    image: raushan,
  },
  {
    name: 'Yogendra Suwal',
    position: 'Head - Debt Risk',
    image: yogendra,
  },
  {
    name: 'Sahana Tuladhar',
    position: 'Head - Internal Audit',
    image: sahana,
  },
  {
    name: '',
    position: 'Head - Business Develepment',
    image: gurkhaLogo,
  },
  {
    name: 'Sunina Malakar',
    position: 'Head - Treasury',
    image: sunena,
  },
  {
    name: 'Shanti Thing',
    position: 'Head - Central Operation',
    image: shantiThing,
  },
  {
    name: 'Subash Rai',
    position: 'Head - D.P Section',
    image: subashRai,
  },
]
