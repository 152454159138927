import React, { useState, useEffect } from "react";
import Contact from "../../Components/Contact";
import ManagerCard from "./ManagerCard";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
export default function CompanyAndInformationOfficer() {
  const [bannerDetail, setBannerDetail] = useState();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === "company-secretary") {
      document.title = "Company Secretary";
      setBannerDetail({
        banner: {
          breadcrumb: ["Home", "Company Secretary"],
          bannerTitle: "Company Secretary",
        },
      });
    }
    if (id === "information-officers") {
      document.title = "Information Officers";
      setBannerDetail({
        banner: {
          breadcrumb: ["Home", "About Us", "Information Officers"],
          bannerTitle: "Information Officers",
        },
      });
    }

    if (id === "grievance-handling-officer") {
      document.title = "Grievance Handling Officer";
      setBannerDetail({
        banner: {
          breadcrumb: ["Home", "About Us", "Grievance Handling Officer"],
          bannerTitle: "Grievance Handling Officer",
        },
      });
    }
  }, [id]);

  return (
    <>
      {bannerDetail?.banner && (
        <SecondaryBanner bannerData={bannerDetail.banner} />
      )}
      <ManagerCard />
    </>
  );
}
