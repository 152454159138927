import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import AutoLoan from "./AutoLoan";
import GoldLoan from "./GoldLoan";
import LoanAgainstFDR from "./LoanAgainstFDR";
import MarginLending from "./MarginLending";
import MortgageLoan from "./MortgageLoan";
import ProfessionalLoan from "./ProfessionalLoan";
import RetailRelatedProduct from "./RetailRelatedProduct";
const RetailLoan = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    switch (pageID) {
      case "mortgage-loan":
        document.title = "Mortgage Loan";
        return "Mortgage Loan";
      case "auto-loan":
        document.title = "Auto Loan";
        return "Auto Loan";
      case "professional-loan":
        document.title = "Professional Loan";
        return "Professional Loan";
      case "gold-loan":
        document.title = "Gold Loan";
        return "Gold Loan";
      case "margin-lending":
        document.title = "Margin Lending";
        return "Margin Lending";
      case "loan-fdr":
        document.title = "Loan Against FDR";
        return "Loan Against FDR";
      default:
        return "";
    }
  }
  const bannerData = {
    breadcrumb: [
      "Home",
      "Explore Products",
      "Loan and Advance",
      "Retail Loan",
      getPageTitle(id),
    ],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-savings fade-in">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "mortgage-loan" && <MortgageLoan />}
              {id === "auto-loan" && <AutoLoan />}
              {id === "professional-loan" && <ProfessionalLoan />}
              {id === "gold-loan" && <GoldLoan />}
              {id === "margin-lending" && <MarginLending />}
              {id === "loan-fdr" && <LoanAgainstFDR />}
            </div>
            <div className="col-md-3">
              <RetailRelatedProduct pageID={id} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default RetailLoan;
