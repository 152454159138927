import React from "react";
import { Link } from "react-router-dom";
export default function BalBhachat() {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">
            <strong>Introduction</strong>
          </p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Bal Bachat Saving account is the product designed to attract
            children. It is the privileged account for the children to encourage
            saving.
          </p>
        </div>
        <div className="sub-text-container">
          <ul>
            <li>
              <strong>Interest Rate</strong>:{" "}
              <span>
                <Link to="/interest-rates">As per Published rate</Link>
              </span>
            </li>
            <li>
              <strong>Debit Card Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Internet Banking Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Mobile Banking Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>ABBS</strong>: Free
            </li>
            <li>
              <strong>Locker</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Cheque Book</strong>: Free
            </li>
            <li>
              <strong>Interest Calculation</strong>: Interest Calculation on a
              daily basis and payable quarterly
            </li>
            <li>
              <strong>Minimum Balance</strong>: NPR 100.00
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">
              <strong>Features:</strong>
            </p>
          </div>
          <ul>
            <li>Free Cheque book issuance.</li>
            <li>No restrictions for withdrawals and deposits</li>
            <li>Internet Banking, Mobile banking and Debit Card facility</li>
            <li>Free Account Statement on request</li>
            <li>Any Branch Banking Services (ABBS)</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
