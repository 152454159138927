import React, { useState } from "react";
import EmiCalculator from "../../Components/EmiCalculator";
import FixedDepositCalculator from "../../Components/FixedDepositCalculator";
// import autoTable from "jspdf-autotable";
const EmiFd = () => {
  const [activeTab, setActiveTab] = useState("emi");

  return (
    <section className="section-emi-fd">
      <div className="container">
        <div className="section-emi-fd-container">
          <div className="tab-container">
            <div
              className={`tab emi ${activeTab === "emi" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("emi")}
            >
              <p>Calculate EMI</p>
            </div>
            <div
              className={`tab fixed ${
                activeTab === "fixed" ? "tab-active" : ""
              }`}
              onClick={() => setActiveTab("fixed")}
            >
              <p>Calculate FD</p>
            </div>
          </div>
          {activeTab === "emi" && <EmiCalculator />}
          {activeTab === "fixed" && <FixedDepositCalculator />}
        </div>
      </div>
    </section>
  );
};

export default EmiFd;
