import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import { Table } from "../../Components/Table";
import tableData from "./pageData.json";
const PromoterShareHolder = () => {
  const { promoterShareHolder } = tableData;
  const bannerData = {
    breadcrumb: ["Home", "About Us", "Promoter Shareholders"],
    bannerTitle: "Promoter Shareholders",
  };

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-table">
        <div className="container">
          <div className="tables fourth-variant">
            <Table
              tableHeader={promoterShareHolder.tableHeader}
              tableData={promoterShareHolder.tableData}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default PromoterShareHolder;
