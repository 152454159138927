import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import BalBhachat from "./BalBhachat";
import CoupleSaving from "./CoupleSaving";
import GurkhaPensionSaving from "./GurkhaPensionSaving";
import GurkhasSaving from "./GurkhasSaving";
import JesthaNagarik from "./JesthaNagarik";
import NariBachat from "./NariBachat";
import PayrollSaving from "./PayrollSaving";
import RegularSaving from "./RegularSaving";
import RemmittanceSaving from "./RemmittanceSaving";
import ShareholderSaving from "./ShareholderSaving";
import SpecialSaving from "./SpecialSaving";
import StaffSaving from "./StaffSaving";
import StudentSaving from "./StudentSaving";
import WelfareSaving from "./WelfareSaving";
import NonProfitable from "./NonProfitable";
// import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";
export default function Saving() {
  const [pageData, setPageData] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    window.scrollTo(0, 0);
    switch (id) {
      case "gurkhas-saving":
        document.title = "Gurkhas Saving";
        setPageData(GurkhasSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Gurkhas Saving",
          ],
          bannerTitle: "Gurkhas Saving",
        });
        break;
      case "nari-bachat":
        document.title = "Nari Bachat";
        setPageData(NariBachat);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Nari Bachat",
          ],
          bannerTitle: "Nari Bachat",
        });
        break;
      case "bal-bachat":
        document.title = "Bal Bachat";
        setPageData(BalBhachat);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Bal Bachat",
          ],
          bannerTitle: "Bal Bachat",
        });
        break;
      case "regular-saving":
        document.title = "Regular Saving";
        setPageData(RegularSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Regular Saving",
          ],
          bannerTitle: "Regular Saving",
        });
        break;
      case "payroll-saving":
        document.title = "Payroll Saving";
        setPageData(PayrollSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Payroll Saving",
          ],
          bannerTitle: "Payroll Saving",
        });
        break;
      case "couple-saving":
        document.title = "Couple Saving";
        setPageData(CoupleSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Couple Saving",
          ],
          bannerTitle: "Couple Saving",
        });
        break;
      case "welfare-saving":
        document.title = "Welfare Saving";
        setPageData(WelfareSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Welfare Saving",
          ],
          bannerTitle: "Welfare Saving",
        });
        break;
      case "student-saving":
        document.title = "Student Saving";
        setPageData(StudentSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Student Saving",
          ],
          bannerTitle: "Student Saving",
        });
        break;
      case "non-profitable-org":
        document.title = "Non-profitable org";
        setPageData(NonProfitable);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Non-profitable Organization",
          ],
          bannerTitle: "Non-profitable Organization",
        });
        break;
      case "jesthanagarik":
        document.title = "Jestha Nagarik";
        setPageData(JesthaNagarik);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Jestha Nagarik",
          ],
          bannerTitle: "Jestha Nagarik",
        });
        break;
      case "shareholder-saving":
        document.title = "Shareholders Saving";
        setPageData(ShareholderSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Shareholders Saving",
          ],
          bannerTitle: "Shareholders Saving",
        });
        break;
      case "staff-saving":
        document.title = "Staff Saving";
        setPageData(StaffSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Staff Saving",
          ],
          bannerTitle: "Staff Saving",
        });
        break;
      case "gurkha-pension-saving":
        document.title = "Gurkha Pension Saving";
        setPageData(GurkhaPensionSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Gurkha Pension Saving",
          ],
          bannerTitle: "Gurkha Pension Saving",
        });
        break;
      case "special-saving":
        document.title = "Special Saving";
        setPageData(SpecialSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Special Saving",
          ],
          bannerTitle: "Special Saving",
        });
        break;
      case "remittance-saving":
        document.title = "Remittance Saving";
        setPageData(RemmittanceSaving);
        setBannerData({
          breadcrumb: [
            "Home",
            "Explore Products",
            "Deposit",
            "Saving",
            "Remittance Saving",
          ],
          bannerTitle: "Remittance Saving",
        });
        break;

      default:
        break;
    }
  }, [id]);

  const sideBar = [{ id: "nari-bachat", name: "Nari Bachat", link: "/saving/nari-bachat" }, { id: "bal-bachat", name: "Bal Bachat", link: "/saving/bal-bachat" }, { id: "regular-saving", name: "Regular Saving", link: "/saving/regular-saving" }, { id: "payroll-saving", name: "Payroll Saving", link: "/saving/payroll-saving" }, { id: "couple-saving", name: "Couple Saving", link: "/saving/couple-saving" }, { id: "welfare-saving", name: "Welfare Saving", link: "/saving/welfare-saving" }, { id: "student-saving", name: "Student Saving", link: "/saving/student-saving" }, { id: "non-profitable-org", name: "Non-profitable Organization Saving", link: "/saving/non-profitable-org" }, { id: "jesthanagarik", name: "Jestha Nagarik", link: "/saving/jesthanagarik" }, { id: "shareholder-saving", name: "Shareholders Saving", link: "/saving/shareholder-saving" }, { id: "staff-saving", name: "Staff Saving", link: "/saving/staff-saving" }, { id: "gurkha-pension-saving", name: "Gurkha Pension Saving", link: "/saving/gurkha-pension-saving" }, { id: "special-saving", name: "Special Saving", link: "/saving/special-saving" }, { id: "remittance-saving", name: "Remittance Saving", link: "/saving/remittance-saving" }]
  return (
    <>
      {bannerData && <SecondaryBanner bannerData={bannerData} />}
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">{pageData}</div>
            <div className="col-md-3">
              <div className="related-products">
                <div className="header-box">
                  <div className="title" style={{ marginBottom: "35px" }}>
                    <p>
                      <strong>Related Products</strong>
                    </p>
                  </div>
                </div>
                <ul>
                  {sideBar.map((item, index) =>
                    <>
                      {id !== item.id &&
                        <li key={index}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      }

                    </>

                  )}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
