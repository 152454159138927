import React, { useEffect } from "react";
import { useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
import CardReport from "../../Components/CardReport";
import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";

export default function SubsuduzedLoanDisClosure() {
  const initialState = {
    selectedYear: "all",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const bannerData = {
    breadcrumb: ["Home", "Subsidized Loan Disclosure"],
    bannerTitle: "Subsidized Loan Disclosure",
  };
  const cardData = [
    {
      id: 4,
      title: "Subsidized Loan Poush 2079",
      link: "https://gurkhasfinance.com.np/assets/SubsidizedLoanDisclosure/List of Subsidized Loan Poush 2079.pdf",
      year: "2079/80",
    },
    {
      id: 3,
      title: "Subsidized Loan Ashoj 2079",
      link: "https://gurkhasfinance.com.np/assets/SubsidizedLoanDisclosure/List of Subsidized Loan Ashoj 2079.pdf",
      year: "2079/80",
    },
    {
      id: 2,
      title: "Subsidized Loan Ashad 2079",
      link: "https://gurkhasfinance.com.np/assets/SubsidizedLoanDisclosure/List of Subsidized Loan Ashad 2079.pdf",
      year: "2078/79",
    },
    {
      id: 1,
      title: "Subsidized Loan Chaitra 2078",
      link: "https://gurkhasfinance.com.np/assets/SubsidizedLoanDisclosure/List of Subsidized Loan Chaitra 2078.pdf",
      year: "2077/78",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                id="year-dropdown"
                onClick={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2079/80">2079/80</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    )
                )}
          </div>
        </div>
      </section>
    </main>
  );
}
