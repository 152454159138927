import React from "react";
import { useEffect } from "react";
import CardDownload from "../../Components/CardDownload";
import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";

const Notice = () => {
  useEffect(() => {
    document.title = "Notice";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Notice"],
    bannerTitle: "Notice",
  };
  const cardData = [
    {
      id: 1,
      title:
        "तपसिलमा उल्लेखित ऋणीहरुलाई कर्जा चुक्ता गर्न आउने ३५ दिने अत्यन्त जरूरी सूचना - 2078-11-13",
      link: "https://gurkhasfinance.com.np/assets/notice/35 Days notice-ManBdr_ Rupa, Fani Lal, Trimurti 35 Days Notice - 2078-11-13.pdf",
      year: "",
    },
    {
      id: 2,
      title:
        "तपसिलमा उल्लेखित ऋणीलाई कर्जा चुक्ता गर्न आउने ३५ दिने अतय्न्तै जरुरी सूचना",
      link: "https://gurkhasfinance.com.np/assets/notice/35 Days notice-Hemaj Maching Center-Mangsir 3, 2078.pdf",
      year: "",
    },
    {
      id: 3,
      title: "35 Days Notice - Bhadra 22, 2078",
      link: "https://gurkhasfinance.com.np/assets/notice/35 Days notice-Gurkhas finance-final 11- Bhadra 22, 2078.pdf",
      year: "",
    },
    {
      id: 4,
      title: "Gair Banking Sampati Bikri - Baishak 8, 2077",
      link: "https://gurkhasfinance.com.np/assets/notice/Gair Banking notice-Gurkhas Finance-final(1).jpg",
      year: "",
    },
  ];
  const officialCardData = [
    {
      id: 1,
      title:
        "गोर्खाज फाइनान्स लिमिटेडका सम्पूर्ण खातावाला ग्राहक महानुभावहरुलाई अत्यन्त जरुरी सूचना",
      link: "https://gurkhasfinance.com.np/assets/notice/kyc update 11 2078-11-4.jpg",
      year: "",
    },
    {
      id: 2,
      title: "Deactivate Account",
      link: "https://gurkhasfinance.com.np/assets/notice/Ten Years-Feb 16, 2022.pdf",
      year: "",
    },
    {
      id: 3,
      title: "Contact List for Lockdown Information",
      link: "https://gurkhasfinance.com.np/assets/notice/corona.png",
      year: "",
    },
    {
      id: 4,
      title: "संचालक निर्वाचन कार्यक्रमको सूचना April 25, 2021",
      link: "https://gurkhasfinance.com.np/assets/notice/new.png",
      year: "",
    },
    {
      id: 5,
      title: "छब्बीसाैँ वार्षिक साधारणसभा सम्बन्धी सूचना April 11, 2021",
      link: "https://gurkhasfinance.com.np/assets/notice/guf.jpg",
      year: "",
    },
    {
      id: 6,
      title: "गैर बैंबिङ्ग सम्पत्ति बिक्री सम्बन्धी​ Mar 26, 2021",
      link: "https://gurkhasfinance.com.np/assets/notice/Gair Banking notice-Gurkhas Finance-final.jpg",
      year: "",
    },
    {
      id: 7,
      title: "DMAT Service Mar 26, 2021",
      link: "https://gurkhasfinance.com.np/assets/notice/dmat.pdf",
      year: "",
    },
    {
      id: 8,
      title: "Information regarding Branchless Banking(BLB)",
      link: "https://gurkhasfinance.com.np/assets/notice/Information regarding Branchless Banking(BLB).pdf",
      year: "",
    },
    {
      id: 9,
      title: "Notice regarding CRN of Foreign Employment",
      link: "https://gurkhasfinance.com.np/assets/notice/Notice regarding CRN of Foreign Employment.pdf",
      year: "",
    },
  ];
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-report">
        <div className="container" style={{ backgroundColor: "white" }}>
          <div className="header-box" style={{ padding: "20px 0 0 55px" }}>
            <p className="title">Vendor/Auction</p>
          </div>
          <div className="card-row" style={{ padding: "25px 43px 70px 47px" }}>
            {cardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
        <div className="container" style={{ backgroundColor: "white" }}>
          <div className="header-box" style={{ padding: "20px 0 0 55px" }}>
            <p className="title">Official Notice</p>
          </div>
          <div className="card-row" style={{ padding: "25px 43px 70px 47px" }}>
            {officialCardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Notice;
