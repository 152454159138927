import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import CallDeposit from "./CallDeposit";
import CurrentDeposit from "./CurrentDeposit";

const OtherDeposit = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    if (pageID === "current-deposit") {
      document.title = "Current Deposit";
      return "Current Deposit";
    } else if (pageID === "call-deposit") {
      document.title = "Call Deposit";
      return "Call Deposit";
    }
  }
  const bannerData = {
    breadcrumb: [
      "Home",
      "Explore Products",
      "Deposit",
      "Other Deposit Product",
      getPageTitle(id),
    ],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "current-deposit" && <CurrentDeposit />}
              {id === "call-deposit" && <CallDeposit />}
            </div>
            <div className="col-md-3">
              <div className="related-products">
                <div className="header-box">
                  <div className="title" style={{ marginBottom: "35px" }}>
                    <p>
                      <strong>Related Products</strong>
                    </p>
                  </div>
                </div>
                <ul>
                  {id !== "current-deposit" && (
                    <li>
                      <Link to="/other-deposit/current-deposit">
                        Current Deposit
                      </Link>
                    </li>
                  )}
                  {id !== "call-deposit" && (
                    <li>
                      <Link to="/other-deposit/call-deposit">Call Deposit</Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OtherDeposit;
