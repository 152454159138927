import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import Overview from "./Overview";
import Contact from "../../Components/Contact";
export default function Introduction() {
  useEffect(() => {
    document.title = "Introduction";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "About Us", "Introduction"],
    bannerTitle: "Introduction",
  };

  return (
    <main class="page-introduction">
      <SecondaryBanner bannerData={bannerData} />
      <Overview />
    </main>
  );
}
