import React, { useState, useEffect } from 'react'
import { data } from './BranchManagerData'

import ReactPaginate from 'react-paginate'

export default function ManagerCard() {
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 10
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    setCurrentItems(data.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(data.length / 10))
  }, [itemOffset])
  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % data.length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    )
    setItemOffset(newOffset)
  }
  return (
    <>
      <section className="section-sub-header">
        <div className="container">
          <div className="content">
            <div className="location-dropdown">
              <select>
                <option>All Over Nepal</option>
              </select>
            </div>
            <div className="text-container">
              <p className="semi-bold-text">
                Total Branches all over Nepal : 27
              </p>
              {/* <p className="small-light-text">Last Updated: 2022/01/22</p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="section-manager-card" id="branch-managers">
        <div className="container">
          <div className="content">
            {currentItems?.map((item, index) => (
              <div className="manager-card" key={index}>
                <div className="text-container">
                  <div className="header-box">
                    <p className="title">{item.OfficeLocation} Branch</p>
                  </div>
                  <div className="sub-header">
                    <p className="caption-big">{item.name}</p>
                    {item.name == 'Uma Raj Gurung' && (
                      <p className="caption-small">Branch Manager</p>
                    )}
                    {item.name == 'Kalyani Rai' && (
                      <p className="caption-small">
                        Extension Counter Incharge
                      </p>
                    )}
                    {item.name == 'Uma Raj Gurung' ||
                    item.name == 'Kalyani Rai' ? (
                      <></>
                    ) : (
                      <p className="caption-small">Branch Manager</p>
                    )}
                    {/* <p className="caption-small">Branch Manager</p> */}
                  </div>
                  <p className="small-light-text">{item.province} , Nepal</p>
                  {item.phone && (
                    <p className="small-light-text">{`Phone : ${item.phone}`}</p>
                  )}
                  {item.email && (
                    <p className="small-light-text">{`Email : ${item.email}`}</p>
                  )}
                </div>
                <div className="image-container">
                  <img src={item.image} alt="" style={{ width: '196px' }} />
                </div>
              </div>
            ))}
          </div>

          <ReactPaginate
            breakLabel="..."
            pageCount={pageCount}
            pageRangeDisplayed={10}
            renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
            className="pagination-button"
          />
        </div>
      </section>
    </>
  )
}
