import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
// import secondaryBannerImage from "../../images/secondary-banner.png";
import Asba from "./Asba";
import DebitCard from "./DebitCard";
import Demat from "./Demat";
import EBanking from "./EBanking";
import LockerServices from "./LockerServices";
import MobileBanking from "./MobileBanking";
import QrScanAndPay from "./QrScanAndPay";
import RelatedProduct from "./RelatedProduct";
import RemitServices from "./RemitServices";

const TransactionalBanking = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    switch (pageID) {
      case "e-banking":
        document.title = "E-banking";
        return "E-Banking";
      case "mobile-banking":
        document.title = "Mobile Banking";
        return "Mobile Banking";
      case "qr-scan-pay":
        document.title = "QR Scan and Pay";
        return "QR Scan and Pay";
      case "debit-card":
        document.title = "Debit Card";
        return "Debit Card";
      case "remit-services":
        document.title = "Remit Services";
        return "Remit Services";
      case "locker-services":
        document.title = "Locker Services";
        return "Locker Services";
      case "demat":
        document.title = "DEMAT";
        return "DEMAT";
      case "asba":
        document.title = "ASBA/C-ASBA Service";
        return "ASBA/C-ASBA Service";
      default:
        return "";
    }
  }

  const bannerData = {
    breadcrumb: ["Home", "TransactionalBanking", getPageTitle(id)],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-secondary-banner">
        <div className="container">
          {/* <div className="image-container">
            <img src={secondaryBannerImage} alt="" />
          </div> */}
        </div>
      </section>
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "e-banking" && <EBanking />}
              {id === "mobile-banking" && <MobileBanking />}
              {id === "qr-scan-pay" && <QrScanAndPay />}
              {id === "debit-card" && <DebitCard />}
              {id === "remit-services" && <RemitServices />}
              {id === "locker-services" && <LockerServices />}
              {id === "asba" && <Asba />}
              {id === "demat" && <Demat />}
            </div>
            <div className="col-md-3">
              <RelatedProduct pageId={id} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TransactionalBanking;
