import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const EmiCalculator = () => {
  const [principal, setPrincipal] = useState("5000000");
  const [interestRate, setInterestRate] = useState(8);
  const [year, setYear] = useState(10);
  const [emi, setEMI] = useState(60663.8);
  const [annualEMI, setAnnualEMI] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [prevInterestRate, setPrevInterestRate] = useState(8);

  useEffect(() => {
    function getEmiValue() {
      let monthlyRate = interestRate / (12 * 100);
      let months = year * 12;
      setEMI(
        (
          (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
          (Math.pow(1 + monthlyRate, months) - 1)
        ).toFixed(2)
      );
      setAnnualEMI(emi * months - Number(principal));
      setTotalAmount(Number(principal) + Number(annualEMI));
    }
    getEmiValue();
  }, [annualEMI, emi, interestRate, principal, year]);

  // remove commas and npr from principal
  function getPrincipalNumber(e) {
    // console.log(e);
    let plainValue = e.target.value;
    // let plainValue = Number(e.target.value.match(/\d+/g, "").join(""));
    if (
      plainValue &&
      plainValue.match(/\d+/g, "").join("") > Number(e.target.max)
    ) {
      setPrincipal(Number(e.target.max));
    } else if (
      Number(e.target.value) < Number(e.target.min) &&
      e.key === "Enter"
    ) {
      setPrincipal(Number(e.target.min));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setPrincipal(e.target.value);
    } else {
      setPrincipal(plainValue.match(/\d+/g, "").join(""));
      console.log("nothing");
    }
  }

  function isNumber(str) {
    if (str.trim() === '') {
      return false;
    }

    return !isNaN(str);
  }
  function getInterestNumber(e) {
    let plainValue = e.target.value;
    if (e.target.value === "") {
      setInterestRate("");
    } else if (Number(e.target.value) > Number(e.target.max)) {
      setInterestRate(Number(e.target.max));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setInterestRate(Number(e.target.min));
    } else {
      if (isNaN(+e.target.value)) {
        setInterestRate(prevInterestRate);
      } else {
        setInterestRate(e.target.value);

        if (Number(e.target.value) > Number(e.target.max)) {
          setPrevInterestRate(e.target.max)
        } else if (Number(e.target.value) < Number(e.target.min)) {
          setPrevInterestRate(e.target.min)
        }
        else {
          setPrevInterestRate(e.target.value)
        }



      }
    }
  }
  function getYearNumber(e) {
    let plainValue = e.target.value;
    if (e.target.value === "") {
      console.log(e.target.value);
      setYear("");
    } else if (Number(e.target.value) > Number(e.target.max)) {
      setYear(Number(e.target.max));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setYear(Number(e.target.min));
    } else {
      setYear(Number(plainValue.match(/\d+/g, "").join("")));
    }
  }
  function converHTMLFileToPDF() {
    var doc = new jsPDF("l", "mm", [1200, 1210]);

    let bodyArray = [];
    for (let i = 1; i <= year * 12; i++) {
      let month = i;
      console.log(year);
      let amountLeft = totalAmount - emi * i;
      console.table("amount left", emi * i);
      if (amountLeft <= 0) {
        amountLeft = 0;
      }
      bodyArray.push([
        month,
        Number(amountLeft).toLocaleString("en-IN"),
        Number(emi).toLocaleString("en-IN"),
      ]);
    }
    doc.autoTable({
      theme: "grid",
      headStyles: {
        fontSize: 40,
        halign: "center",
        valign: "middle",
        cellWidth: 200,
        minCellHeight: 30,
      },
      bodyStyles: {
        fontSize: 35,
        halign: "center",
        valign: "middle",
        cellWidth: 200,
        minCellHeight: 20,
      },
      margin: 50,
      tableWidth: "auto",
      pageBreak: "auto",
      head: [["Month", "Amount Left", "Monthly EMI"]],
      body: [...bodyArray],
    });
    // doc.output("dataurlnewwindow");
    doc.save("Monthly_Payment.pdf");
  }
  return (
    <div className="row loan-content active-loan-content emi" id="emi">
      <div className="col-lg-7">
        <div className="emi-fd-left">
          <div className="box-header">
            <h2>Calculate your EMI instantly!</h2>
            <h4>Find out your monthly payable amount.</h4>
          </div>
          <div className="emi-fd-value-container">
            <div className="loan-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">My loan requirement is</p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="100000"
                    max="100000000"
                    id="loan-slider"
                    className="slider"
                    value={principal}
                    onChange={(e) => setPrincipal(e.target.value)}
                  />
                  <div
                    id="loan-selector"
                    className="selector"
                    style={{ left: `${(principal / 100000000) * 100}%` }}
                  >
                    <div className="loan-selectBtn selectBtn"></div>
                  </div>
                  <div
                    id="loan-progressBar"
                    className="progressBar"
                    style={{
                      width: `${(principal / 100000000) * 100}%`,
                    }}
                  ></div>
                </div>
                <input
                  type="text"
                  className="trailStyle"
                  id="loan-progressBar-info"
                  min="100000"
                  max="100000000"
                  value={Number(principal).toLocaleString("en-IN")}
                  onInput={(e) => {
                    getPrincipalNumber(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getPrincipalNumber(e);
                    }
                  }}
                />
              </div>
            </div>
            <div className="interest-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">
                I am looking at an interest rate of
              </p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="1"
                    max="20"
                    step="0.01"
                    id="interest-slider"
                    className="slider"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                  />
                  <div
                    id="interest-selector"
                    className="selector"
                    style={{ left: `${(interestRate / 20) * 100}%` }}
                  >
                    <div className="interest-selectBtn selectBtn"></div>
                  </div>
                  <div
                    id="interest-progressBar"
                    className="progressBar"
                    style={{ width: `${(interestRate / 20) * 100}%` }}
                  ></div>
                </div>
                <div className="input-value">
                  <label htmlFor="interest-progressBar-info">
                    <input
                      id="interest-progressBar-info"
                      min="1"
                      max="20"
                      type="text"
                      value={interestRate}
                      onChange={(e) => getInterestNumber(e)}

                    />
                    %PA
                  </label>
                </div>
              </div>
            </div>
            <div className="year-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">I plan to repay the amount in</p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="1"
                    max="40"
                    id="year-slider"
                    className="slider"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                  <div
                    id="year-selector"
                    className="selector"
                    style={{ left: `${(year / 40) * 100}%` }}
                  >
                    <div className="year-selectBtn selectBtn"></div>
                  </div>
                  <div
                    id="year-progressBar"
                    className="progressBar"
                    style={{ width: `${(year / 40) * 100}%` }}
                  ></div>
                </div>
                <div className="input-value">
                  <label htmlFor="year-progressBar-info">
                    <input
                      id="year-progressBar-info"
                      min="1"
                      max="40"
                      value={year}
                      onChange={(e) => getYearNumber(e)}
                    />{" "}
                    Years
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="emi-fd-right">
          <div className="box-header">
            <h5>Your monthly <br /> EMI is:</h5>
            <p>
              <span id="total-montly-emi">{`${Number(emi).toLocaleString(
                "en-IN",
                {
                  style: "currency",
                  currency: "NPR",
                }
              )}`}</span>
            </p>
          </div>
          <div className="box-body">
            <div className="principle box-body-flex">
              <p>Principal:</p>
              <p>
                <span id="principal-value-dynamic">
                  {`${Number(principal).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "NPR",
                  })}`}
                </span>{" "}
              </p>
            </div>
            <div className="interest-payable  box-body-flex">
              <p>Interest Payable:</p>
              <p>
                <span id="interest-payable-value-dynamic">
                  {`${Number(annualEMI).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "NPR",
                  })}`}
                </span>
              </p>
            </div>
            <div className="principle box-body-flex">
              <p>Total Amount Payable:</p>
              <p>
                <span id="total-payable-value-dynamic">
                  {`${Number(totalAmount).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "NPR",
                  })}`}
                </span>
              </p>
            </div>
            <div className="button-container">
              <button className="btn-primary" onClick={converHTMLFileToPDF}>
                Download Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmiCalculator;
