import React from "react";
import secondaryBannerImage from "../../images/dmate.png";

const Demat = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Introduction</p>
            <p className="small-light-text">
              Gurkhas Finance Ltd obtained the license to act as Depository
              Participant (DP) and provide Demat related services. As a DP,
              Gurkhas Finance has been providing below listed services to its
              customers.
            </p>
          </div>
        </div>
        <div className="text-list">
          <div className="header-box " style={{ marginTop: "40px" }}>
            <p className="title">Facilities</p>
          </div>
          <ul>
            <li> Demat Account Opening</li>
            <li> Dematerialization of Share certificates</li>
            <li> Rematerialization of Dematerialized shares</li>
            <li>
              {" "}
              Demat Statement and Portfolio value viewing facility in real time
              from Meroshare Service
            </li>
            <li> Deposit, Withdraw and Transfer of securities</li>
            <li> Provide updated statement of account</li>
            <li>
              {" "}
              Pledge and un-pledge of securities with secure and convenient
              electronic procedures
            </li>
            <li>
              {" "}
              To safeguard the investors’ securities into the electronic form
              and to debit/credit the particular investor’s account as per the
              transactions occurred in the book entry system
            </li>
          </ul>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default Demat;
