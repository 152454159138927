import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import FixedRelatedProduct from "./FixedRelatedProduct";
import IndividualDeposit from "./IndividualDeposit";
import InstitutionalDeposit from "./InstitutionalDeposit";

const FixedDeposit = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    switch (pageID) {
      case "individual":
        document.title = "Individual Fixed Deposit";
        return "Individual Fixed Deposit";
      case "institutional":
        document.title = "Institutional Fixed Deposit";
        return "Institutional Fixed Deposit";
      default:
        return;
    }
  }

  const bannerData = {
    breadcrumb: [
      "Home",
      "Explore Products",
      "Deposit",
      "Fixed Deposit",
      getPageTitle(id),
    ],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "individual" && <IndividualDeposit />}
              {id === "institutional" && <InstitutionalDeposit />}
            </div>
            <div className="col-md-3">
              <FixedRelatedProduct pageID={id} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FixedDeposit;
