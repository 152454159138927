import React from "react";

const AutoLoan = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            An auto loan is provided for purchase of vehicles for either
            personal use or commercial use. The limit of financing shall be as
            per NRB Directive.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Financing available for</p>
          </div>
          <ul>
            <li>Personal car financing</li>
            <li>Used Personal Car financing</li>
            <li>Taxi Financing</li>
            <li>Commercial vehicle financing.</li>
            <li>Vehicle for Business use.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Benefits</p>
          </div>
          <ul>
            <li>Competitive interest rates.</li>
            <li>Quick Processing Time.</li>
            <li>Easy documentation.</li>
            <li>Personalized service.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>Up-to 80% of financing (Depends upon nature of vehicles)</li>
            <li>
              Flexible loan Tenure (Maximum up to 8 years (depends upon nature
              of vehicles)
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Copy of Citizenship</li>
            <li>PP size Photograph (applicable for all).</li>
            <li>Copy of Quotation of the Vehicle for New Vehicle.</li>
            <li>
              Valuation Report from Bank’s approved Valuers for used Private
              Vehicle.
            </li>
            <li>Salary Certificate (for salaried employees).</li>
            <li>Certified Statement of Income (for professionals).</li>
            <li>
              Contracts/ Agreements (for individuals with fixed Income Sources).
            </li>
            <li>
              Registration Document at the concerned Nepal Government Office and
              Tax Office (for firms/companies).
            </li>
            <li>
              Memorandum of Articles and Association (if Private Limited).
            </li>
            <li>Partnership Deed (for Partnership firm).</li>
            <li>Board Resolution (if Private Limited and Institutions).</li>
            <li>
              At least 2 years past audited financials for business income.
            </li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default AutoLoan;
