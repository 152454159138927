import React from "react";

const MortgageLoan = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            A mortgage loan or, simply, the mortgage is used either by
            purchasers of real property to raise funds to buy real estate, or
            alternatively by existing property owners to raise funds for any
            purpose while putting a lien on the property being mortgaged. The
            loan is “secured” on the borrower’s property through a process known
            as mortgage origination.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Financing for</p>
          </div>
          <ul>
            <li>House construction loan.</li>
            <li>Readymade house purchase.</li>
            <li>Plot purchase</li>
            <li>Renovation / Extension of your house.</li>
            <li>Refinancing from your existing financier.</li>
            <li>Loan against own property.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Benefits</p>
          </div>
          <ul>
            <li>Loan against own property</li>
            <li>Quick Processing Time. </li>
            <li>Easy documentation. </li>
            <li>Personalized service.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>
              Up-to 40% of the property value (Depend upon location and nature
              of loan)
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Passport size photo of applicants/guarantor.</li>
            <li>Copy of citizenship document of borrowers/guarantor.</li>
            <li>Copy of Land ownership document (lal purja).</li>
            <li>Copy of Transfer deed (Rajinama).</li>
            <li>Blue Print of Land.</li>
            <li>
              Building construction approval from Municipality / VDC along with
              drawing plans (applicable where building is involved).
            </li>
            <li>Char Killa (four boundary).</li>
            <li>Land revenue Tax Receipt (malpot receipt).</li>
            <li>
              Income certifying document (Salary letter, Audited balance sheet,
              rental agreement etc.).
            </li>
            <li>
              Firm / company registration and PAN in case of income from
              business source.
            </li>
            <li>
              Building completion certificate from Municipality/VDC in case of
              readymade house purchase and loan against property.
            </li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default MortgageLoan;
