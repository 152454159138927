import React, { useEffect, useRef } from "react";
export default function StickyButton() {
  const ref = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      ref.current.style.visibility = "hidden";
      if (window.scrollY > 300) {
        ref.current.style.visibility = "visible";
      }
    });
  }, []);
  return (
    <section className="sticky-button" ref={ref}>
      <li onClick={() => window.scrollTo(0, 0)}>
        <div className="sticky-button-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.667"
            height="10.683"
            viewBox="1170.967 2903.35 18.667 10.683"
          >
            <path
              d="m1180.29 2906.585-7.046 7.053a1.326 1.326 0 0 1-1.881 0 1.343 1.343 0 0 1 0-1.887l7.985-7.99a1.33 1.33 0 0 1 1.836-.04l8.04 8.024c.261.261.39.605.39.944 0 .338-.129.682-.39.943a1.326 1.326 0 0 1-1.88 0l-7.053-7.047Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Icon ionic-ios-arrow-forward"
            />
          </svg>
        </div>
      </li>
    </section>
  );
}
